
import PageHeader from '../../PageHeader';
import EnquiryForm from '../Enquiry/EnquiryForm';
import { Row, Col, Tooltip } from 'antd';
import './Contact.css';
import { ContactsFilled, PhoneFilled, MailFilled } from '@ant-design/icons';
import qualitypolicybg from '../../img/qualitypolicybg.jpg';
import ReactGA from 'react-ga';
import { useEffect } from 'react';
const Contact = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    }, [])
    return (<div>
        <PageHeader title='Contact Us' background={qualitypolicybg} />
        <div className='contact-us-info'>
            <Row>
                <Col xs={24} lg={12} >
                    <div className='contactus'>
                        <div className='contact-us-info-title'>- Flo Pipes & Fittings</div>
                        <br />
                        <Tooltip title="Registered Office Address"><div className='contact-us-info-description'><ContactsFilled className='contact-us-icon' />  Flo Pipes And Fittings Co 5 / 502 A Wing, Suryadarshan Building, S.S.M.Marg, Kumbharwada 2nd Lane, Mumbai - 400004.</div></Tooltip>
                        <Tooltip title=" Office Address"><div className='contact-us-info-description'><ContactsFilled className='contact-us-icon' />  15/C, 4th Floor ,Pandurang Bhuvan Building ,S.S.M.Marg, Kumbharwada 2nd Lane ,Mumbai - 400004.</div></Tooltip>
                        <div className='contact-us-info-description'> <PhoneFilled className='contact-us-icon' /><a href="tel:919820810810">+91 98208 10810</a></div>
                        {/* <div className='contact-us-info-description'><PhoneFilled className='contact-us-icon' /> <a href="tel:919820810810">+91 98208 10810</a></div> */}
                        <div className='contact-us-info-description'> <MailFilled className='contact-us-icon' /><a href="mailto:info@flometal.com">info@flometal.com</a>, <a href="mailto:sales@flometal.com">sales@flometal.com.</a></div>
                    </div>
                </Col>
                <Col xs={24} lg={12}>
                    <EnquiryForm />
                </Col>
            </Row>
        </div>
    </div>)
}
export default Contact;