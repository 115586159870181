import './Products.css';
import PageHeader from '../../PageHeader';
import { Row, Col } from 'antd';
import brassrods from '../../img/Product/brassrods.jpg';
import brassrodbg from '../../img/Product/brassrodbg.jpg';
import '../Pages.css';
import ReactGA from 'react-ga';
import { useEffect } from 'react';

const BrassRodsManfacturer = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    }, [])
    return (<div>
        <PageHeader title='Brass Rods Manfacturer in India' background={brassrodbg} />
        <div className='product-info'>
            <Row gutter={[32, 16]}>
                <Col xs={24} md={12}>
                    <img src={brassrods} width="100%" height="100%" className='product-img' alt='' />
                </Col>
                <Col xs={24} md={12}>
                    <div className='product-info-title'>Brass Rods Manufacturer, Supplier, and Stockists in India</div>
                    <div className='product-info-description'>Flo Pipes &amp; fittings is a well-known <b>Brass Rods Manufacturer, Supplier, and Exporter in India</b>. We have a significant manufacturer and supplier of <u>Brass Rods</u>. Brass Rods are utilised in many sectors, including oil and gas, petroleum, petrochemical, chemical, machine construction, cars, and so on. To suit the demands of our clients, these Brass Rods are available in a range of sizes, specifications, grade materials, and forms.</div>
                </Col>
                <Col xs={0} md={24}><br /></Col>
            </Row>
            <div className='product-info-title'>Brass Rods Supplier, Brass Rods Stockists, Brass Rods Dealer, Brass Rods Manufacturer in India.</div>
            <div className='product-info-description'>Flo Pipes &amp; fittings is one of India's leading manufacturer of high-quality <u>Brass Rods</u> that are used in a variety of industries. <b>Rods</b> is created by pressing a hard circular Brass 'material' over a form and heating it until the Brass is wrought into a Brass Rods. Our <em>Brass</em> provide consistent strength and structure throughout the Brass Rods body. As a result, these Brass Rods can withstand greater temperatures, higher pressures, corrosive environments, and increased mechanical stress</div>
            <div className='product-info-description'>We have a competent team to ensure the quality of <b>Brass Rods</b> and other goods throughout the manufacturing and supplying process, which includes selecting high-quality raw materials, turning them into completed products, identifying, packing, storage, and transportation. We are also the largest Supplier of <a href="/products/socketweld-fittings">Socketweld Fittings</a>.</div>
            <br />
            <div className='product-info-title'>Brass Rods Specifications</div>
            <div className="table-responsive">
                <table border="1" className='product-info-description'>
                    <tbody>
                        <tr>
                            <th colSpan="2"><strong> Brass Rods</strong></th>
                        </tr>
                        <tr>
                            <td><strong>Copper</strong></td>
                            <td> DHP Copper, ETP Copper, DPA Copper OFHC Copper</td>
                        </tr>
                        <tr>
                            <td><strong>Brass</strong></td>
                            <td> 63 / 37 Brass, 70 / 30 Brass, Admirality Brass,
                                Aluminium Brass &amp; other Compositions of Brass.
                            </td>
                        </tr>

                        <tr>
                            <td><strong>Cupronickel </strong></td>
                            <td> 95 / 5 alloy, 90/ 10 alloy &amp; 70 / 30 alloy.</td>
                        </tr>
                        <tr>
                            <td><strong>Bronze </strong></td>
                            <td>Phosphorous Bronze Aluminium Bronze &amp; Gun Metal,
                                Phosphorous Bronze A Bl / A B2 Bush Round.</td>
                        </tr>



                    </tbody>
                </table>
            </div>
            {/* <br />
            <div className='product-info-title'>Brass Rods Supplier - Additional Information</div>
            <div className='product-info-description'>
                <ol>
                    <li>Brass Rods Payment Modes: LC (Letter of Credit), TT (Telegraphic Transfer or Wire Transfer), Cheque, and others.</li>
                    <li>Brass Rods Packaging: Brass Rods are packaged with caution and safety so they reach our client’s as good as he would expect. Packaging Charges Extra.</li>
                    <li>Brass Rods Port of Dispatch: Mumbai ports in the Maharashtra state of India.</li>
                    <li>Brass Rods Tax: 18% GST.</li>
                </ol>
            </div>
            <div style={{ padding: '2vw 0', textAlign: 'center' }}><span className='markup-text'>Flo Pipes & Fittings is a leading Carbon Steel Pipes and Tubes Manufacturer in India.</span></div>
            <br />
            <div className='product-info-title'>Applications & Uses of Brass Rods</div>
            <div className='product-info-description'>
                <ul >
                    <li>Brass Rods used in Sugar Industry.</li>
                    <li>Brass Rods used in Power Industry.</li>
                    <li>Brass Rods used in ShipBuilding Industry.</li>
                    <li>Brass Rods used in Mechanical and Plant engineering.</li>
                    <li>Brass Rods used in Food, Beverage, and Dairy.</li>
                    <li>Brass Rods used in Oil and Gas Plant.</li>
                    <li>Brass Rods used in Process Instrumentation.</li>
                </ul>
            </div> */}
        </div>
    </div>)
}
export default BrassRodsManfacturer;