
import "./Home.css";

const ProductInfo = ({ description, title }) => {
    return (
        <div className='product'>
            <div className='product_title'>{title}</div>
            {description && <p className='product_description' style={{ padding: '1vw 10vw', margin: '0' }}>{description}</p>}
        </div>
    )
}
export default ProductInfo;