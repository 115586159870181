import carbonpipetop from './img/carbonpipetop.jpg';

const PageHeader = ({ title, background }) => {
    const backgroundImg = background || carbonpipetop;
    return (
        <div className="page-header"><div className="page-title-bg" style={{ backgroundImage: `url(${backgroundImg})` }}>
            <div className="page-title" >{title}</div>
        </div>
        </div>)
}
export default PageHeader;