import { Col, Row } from 'antd';
import "./Home.css";
import ProductCard from './ProductCard';
import carbonsteelpipetubes from '../../img/Material/carbonsteelpipetubes.jpg';
import carbonsteel from '../../img/Material/carbonsteel.jpg';
import stainlesssteel from '../../img/Material/stainlesssteel.jpg';

const Materials = () => {
    return (<>
        <Row gutter={[16, 32]} className='col3' style={{ margin: 0 }}>
            <Col xs={24} md={12} lg={8}>
                <ProductCard productImg={carbonsteelpipetubes} title='Carbon Steel Pipes & Tubes' subTitle='All of our products are available in Carbon Steel Pipes in all ASTM Standard.' redirect='/products/carbon-steel-pipes-and-tubes' />
            </Col>
            <Col xs={24} md={12} lg={8}>
                <ProductCard productImg={stainlesssteel} title='STAINLESS STEEL' subTitle='All of our products are available in Stainless Steel in a variety of grades and standards.' redirect='/products/stainless-steel' />
            </Col>
            <Col xs={24} md={12} lg={8}>
                <ProductCard productImg={carbonsteel} title='Carbon Steel' subTitle='All of our products are available in Carbon Steel in a variety of grades and standards.' redirect='/products/carbon-steel-pipes-and-tubes' />
            </Col>
        </Row>
    </>)
}
export default Materials;