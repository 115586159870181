import { Card } from 'antd';
import "./ProductCard.css";
import { RightOutlined } from '@ant-design/icons';

const ProductCard = ({ productImg, title, subTitle, redirect }) => {
    return (
        <a href={redirect}>
        <Card
            hoverable
            style={{ textAlign: 'center', width: '100%' }}
            cover={<img alt="example" src={productImg} />}
        >
            <div className='product-card-title'>{title}</div>
            {subTitle && <div className='product-card-description'>{subTitle}</div>}
            {redirect && <div className='product_card_read_more'><b>READ MORE <RightOutlined /></b></div>}
        </Card>
        </a>)
}
export default ProductCard;