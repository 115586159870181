import './Products.css';
import PageHeader from '../../PageHeader';
import { Row, Col } from 'antd';
import carbonsteelpipetubesmanufacturer from '../../img/Product/carbonsteelpipetubesmanufacturer.jpg';
import carbonsteelpipetubesBg from '../../img/Product/carbonsteelpipetubesBg.jpg';
import '../Pages.css';
import ReactGA from 'react-ga';
import { useEffect } from 'react';
const CarbonSteelPipesAndTubes = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    }, [])
    return (<div>
        <PageHeader title='Carbon Steel Pipes and Tubes Manfacturer in India' background={carbonsteelpipetubesBg}/>
        <div className='product-info'>
            <Row gutter={[32, 16]}>
                <Col xs={24} md={12}>
                    <img src={carbonsteelpipetubesmanufacturer} width="100%" height="100%" className='product-img' alt='' />
                </Col>
                <Col xs={24} md={12}>
                    <div className='product-info-title'>Carbon Steel Pipes and Tubes Manufacturer, Supplier, and Stockists in India</div>
                    <div className='product-info-description'>Flo Pipes & Fittings is a well-known <b>Carbon Steel Pipes and Tubes Manufacturer, Supplier, and Exporter in India</b>. We have a significant manufacturer and supplier of Carbon Steel Pipes and Tubes. Carbon Steel Pipes and Tubes are utilised in many sectors, including oil and gas, petroleum, petrochemical, chemical, machine construction, cars, and so on. To suit the demands of our clients, these Carbon Steel Pipes and Tubes are available in a range of sizes, specifications, grade materials, and forms.</div>
                </Col>
                <Col xs={0} md={24}><br /></Col>
            </Row>
            <div className='product-info-title'>Carbon Steel Pipes and Tubes Supplier, Carbon Steel Pipes and Tubes Stockists, Carbon Steel Pipes and Tubes Dealer, Carbon Steel Pipes and Tubes Manufacturer in India.</div>
            <div className='product-info-description'>Flo Pipes & Fittings is one of India's leading manufacturer of high-quality <u>Carbon Steel Pipes and Tubes</u> that are used in a variety of industries. <b>Carbon Steel Pipes and Tubes</b> is created by pressing a hard circular Carbon 'material' over a form and heating it until the Carbon is wrought into a Carbon Steel Pipes and Tubes. Our <em>Carbon</em> provide consistent strength and structure throughout the Carbon Steel Pipes and Tubes body. As a result, these Carbon Steel Pipes and Tubes can with stand greater temperatures, higher pressures, corrosive environments, and increased mechanical stress.</div>
            <div className='product-info-description'>
                We have a competent team to ensure the quality of <b>Carbon Steel Pipes and Tubes</b> and other goods throughout the manufacturing and supplying process, which includes selecting high-quality raw materials, turning them into completed products, identifying, packing, storage, and transportation. We are also the largest Supplier of <a href="/products/socketweld-fittings">Socketweld Fittings</a>.
            </div>
            <div className='product-info-title'>Carbon Steel Pipes and Tubes Specifications</div>
            <div className="table-responsive">
                <table border="1" className='product-info-description'>
                    <tbody>
                        <tr>
                            <td><strong>Carbon Steel Seamless Pipe Dimensions</strong></td>
                            <td>ASTM, ASME and API</td>
                        </tr>
                        <tr>
                            <td><strong>Api 5l Carbon Steel Pipe Size</strong></td>
                            <td>1/2 NB to 36 NB</td>
                        </tr>
                        <tr>
                            <td><strong>Api 5l Carbon Steel Seamless Pipe Thickness</strong></td>
                            <td>3-12mm</td>
                        </tr>
                        <tr>
                            <td><strong>Schedules</strong></td>
                            <td>SCH 40, SCH 80, SCH 160, SCH XS, SCH XXS, All Schedules</td>
                        </tr>
                        <tr>
                            <td><strong>High Carbon Steel Tube Origin</strong></td>
                            <td>European, Japanese, Korean, US, Russian, Indian (Strictly No Chinese Material)</td>
                        </tr>
                        <tr>
                            <td><strong>Type</strong></td>
                            <td>Seamless / ERW / Welded / Fabricated</td>
                        </tr>
                        <tr>
                            <td><strong>Form</strong></td>
                            <td>Round, Hydraulic Etc</td>
                        </tr>
                        <tr>
                            <td><strong>Carbon Steel Tube Material Length</strong></td>
                            <td>Min 3 Meters, Max18 Meters, or according to customer’s requirement</td>
                        </tr>
                        <tr>
                            <td><strong>Jis Carbon Steel Pipe End</strong></td>
                            <td>Plain End, Beveled End, Treaded</td>
                        </tr>
                        <tr>
                            <td><strong>Specialized in</strong></td>
                            <td>Large Diameter Carbon Steel Pipe, seamless carbon steel pipe, carbon steel pipe suppliers, carbon steel seamless pipe suppliers, carbon steel erw pipe suppliers, carbon steel welded pipe suppliers, Api 5l Carbon Steel Seamless Pipe</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {/* <br />
            <div className='product-info-title'>Carbon Steel Pipes and Tubes Supplier - Additional Information</div>
            <ol className="product-info-description">
                <li>Carbon Steel Pipes and Tubes Payment Modes: LC (Letter of Credit), TT (Telegraphic Transfer or Wire Transfer), Cheque, and others.</li>
                <li>Carbon Steel Pipes and Tubes Packaging: Carbon Steel Pipes and Tubes are packaged with caution and safety so they reach our client’s as good as he would expect. Packaging Charges Extra.</li>
                <li>Carbon Steel Pipes and Tubes Port of Dispatch: Mumbai ports in the Maharashtra state of India.</li>
                <li>Carbon Steel Pipes and Tubes Tax: 18% GST.</li>
            </ol>
            <div style={{ padding: '2vw 0', textAlign: 'center' }}><span className='markup-text'>Flo Pipes & Fittings is a leading Carbon Steel Pipes and Tubes Manufacturer in India.</span></div>
            <br />
            <div className='product-info-title'>Applications & Uses of Carbon Steel Pipes and Tubes</div>
            <ul className="product-info-description">
                <li>Carbon Steel Pipes and Tubes used in Sugar Industry.</li>
                <li>Carbon Steel Pipes and Tubes used in Power Industry.</li>
                <li>Carbon Steel Pipes and Tubes used in ShipBuilding Industry.</li>
                <li>Carbon Steel Pipes and Tubes used in Mechanical and Plant engineering.</li>
                <li>Carbon Steel Pipes and Tubes used in Food, Beverage, and Dairy.</li>
                <li>Carbon Steel Pipes and Tubes used in Oil and Gas Plant.</li>
                <li>Carbon Steel Pipes and Tubes used in Process Instrumentation.</li>
            </ul> */}
        </div>
    </div>)
}
export default CarbonSteelPipesAndTubes;