import './Enquiry.css';
import { Form, Button, Input } from 'antd';
import ReactGA from 'react-ga';
import { useEffect } from 'react';
const EnquiryForm = () => {

    const onFinish = (val) => {
        ReactGA.event({
            category:val.name,
            action:val.number,
            label:val.message,
            value:val.email
        });
        alert('Thank you for your response! We will contact you soon!');
        window.location.href = '/'
    }
    return (
        <div className='enquiry-form'>
            <div className="enquiry-title">Get In Touch</div>
            <br />
            <Form
                name="basic"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your name!',
                        },
                    ]}
                >
                    <Input placeholder="Your Name" className="input-box" />
                </Form.Item>

                <Form.Item
                    name="email"
                    rules={[
                        {
                            type: 'email',
                            message: 'The input is not valid E-mail!',
                        },
                        {
                            required: true,
                            message: 'Please enter your E-mail!',
                        },
                    ]}
                >
                    <Input placeholder="Your Email" className="input-box" />
                </Form.Item>
                <Form.Item
                    name="number"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter number!',
                        }
                    ]}
                >
                    <Input type="number" placeholder="Your Number" className="input-box" />
                </Form.Item>
                <Form.Item
                    name="message"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter message!',
                        },
                    ]}
                >
                    <Input.TextArea placeholder="Your Meassage" className="input-box" />
                </Form.Item>
                <Form.Item>
                    <div style={{ textAlign: 'start' }}>
                        Security Question -<br />
                        <b>What is 4 + 2 ?</b>
                    </div>
                </Form.Item>
                <Form.Item
                    name="answer"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter answer!',
                            pattern: /^[6]$/,

                        },
                    ]}
                >
                    <Input placeholder="Your Answer" className="input-box" />
                </Form.Item>
                <Form.Item >
                    <Button type="primary" htmlType="submit" className="submit-btn">
                        Submit Now
                    </Button>
                </Form.Item>
            </Form>
        </div>)
}
export default EnquiryForm;