import { Row, Col } from "antd";
import PageHeader from "../../PageHeader";
import './QualityPolicy.css';
import qualitypolicy from '../../img/qualitypolicy.png';
import qualitypolicybg from '../../img/qualitypolicybg.jpg';
import ReactGA from 'react-ga';
import { useEffect } from 'react';
const QualityPolicy = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    }, [])
    return (
        <div>
            <PageHeader title='Quality Policy' background={qualitypolicybg} />
            <div className="quality-policy-info">
                <div className="quality-policy-info-title">Quality Assurance:</div>
                <div className="quality-policy-info-description">
                    Thus, main goal of ours is high quality products. We have adopted quality analyst personnel to ensue complete satisfaction to our clients. As
                    there is always a scope of betterment we are continually improving upon quality of products.
                    We have a stringent parameters set for the quality which are followed by all our employees. They see to it that the products are in accordance
                    with the national / international standards.
                    From the time of procurement of raw material till the final delivery of the products, at every stage, our products are checked for various chemical
                    and mechanical properties using the equipment certified by the Government and its agencies.
                    <br />
                    <br />
                    <b><u>These principles are:</u></b>
                    <ul>
                        <li> We care about our clients. </li>
                        <li> We do our absolute best to honor our commitments.</li>
                        <li> We make every effort to always behave with honesty and trust.</li>
                    </ul>
                </div>
                <Row gutter={[32, 16]}>
                    <Col xs={24} md={16}>
                        <div className='quality-policy-info-title'>Quality control:</div>
                        <div className='quality-policy-info-description'>We ensure that the supplies we provide to our clients meet their exact specifications. To satisfy all criteria, our quality control team combines technical experience, knowledge of industry standards, and the most up-to-date inspection instruments and machinery. Our quality assurance system ensures that each product has gone through the following processes and quality systems.
                            <br />
                            <ul>
                                <li>  Process control system. </li>
                                <li>  Machinery & dimensional control. </li>
                                <li>  Certification & supplementary test. </li>
                            </ul>
                        </div>
                    </Col>
                    <Col xs={24} md={8}>
                        <img src={qualitypolicy} width="100%" height="100%" className='quality-policy-img' alt="" />
                    </Col>
                    <Col xs={0} md={24}><br /></Col>
                </Row>
                <div className="quality-policy-info-title">Quality Facilities:</div>
                <div className="quality-policy-info-description">Our staff at Flo Pipes & Fittings adhere to and contribute to the effectiveness of our quality system in all aspects of our operation. Our dedication ensures that we give our clients with uncompromising quality and services, with special emphasis on high quality, tolerance, and traceability. Our quality assurance programme ensures that the highest level of quality and activity is maintained in order to create and achieve the corporate objectives.</div>
                <div className="quality-policy-info-title">Our Excellence:</div>
                <div className="quality-policy-info-description">Flo Pipes & Fittings policy is to obtain manufactured goods under a regulated quality assurance system that complies with all customers' requirements, i.e. order specification, drawings, standard, and records of material traceability, measurements. To produce bespoke goods for our respected customer, we own new and upgraded hi-tech machinery and sophisticated technologies. To meet the demands of our clients, we constantly improve our technology to worldwide standards. Our expert engineering team is dedicated to developing high-performance, high-quality products.</div>
                <div className="quality-policy-info-title">Quality assurance policy:</div>
                <div className="quality-policy-info-description">Flo Pipes & Fittings policy is to obtain manufactured goods under a regulated quality assurance system that complies with all customers' requirements, i.e. order specification, drawings, standards & code, and records of material traceability, measurements. To produce bespoke goods for our respected customer, we own new and upgraded hi-tech machinery and sophisticated technologies. To meet the demands of our clients, we constantly improve our technology to worldwide standards. Our expert engineering team is dedicated to developing high-performance, high-quality products.</div>
                <div className="quality-policy-info-title">Who we are</div>
                <div className="quality-policy-info-description">
                    <ul>
                        <li> <b><u>Manufacturer</u></b> <br />
                            We are professional exporters and manufacturer of stainless steel pipes/tubes, plates, coils, sheets, rods, strips, nuts and bolts grade provided internationally to the whole world.
                        </li>
                        <li> <b><u> Stockist</u></b> <br />
                            We are one of the major stockholders of stainless steel nickel alloys, alloy steel, and duplex / super duplex steel for domestic and international markets.
                        </li>
                        <li> <b><u> Suppliers</u></b> <br />
                            Clients have relied on Flo Pipes & Fittings for the absolute finest in specialty metals, comprehensive processing services, prompt delivery, and professional services for over a year.
                        </li>
                        <li> <b><u>  Exporter</u></b> <br />
                            We are a reputable export firm from India. We are a company with deep roots and a diverse history, as well as a clear vision for the future.                                                </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
export default QualityPolicy;