import PageHeader from "../../PageHeader";
import ProductInfo from "../Home/ProductInfo";
import ProductCard from "../Home/ProductCard";
import { Row, Col } from 'antd';
import '../Home/Home.css';
import './Products.css';
import firstProduct from '../../img/AllProducts/1.png';
import secondProduct from '../../img/AllProducts/2.jpg';
import thirdProduct from '../../img/AllProducts/3.jpg';
import fourthProduct from '../../img/AllProducts/4.jpg';
import fifthProduct from '../../img/AllProducts/5.jpg';
import sixthProduct from '../../img/AllProducts/6.jpg';
import seventhProduct from '../../img/AllProducts/7.jpg';
import eighthProduct from '../../img/AllProducts/8.jpg';
import ninthProduct from '../../img/AllProducts/9.jpg';
import tenthProduct from '../../img/AllProducts/10.jpg';
import eleventhProduct from '../../img/AllProducts/11.jpg';
import twelvethProduct from '../../img/Product/fastenersmain.jpg';

const ProductList = [
    {
        productImg: firstProduct,
        title: 'Carbon Steel Pipes & Tubes',
        subTitle: 'Carbon Steel Pipes & Tubes are made with precision and high quality materials.',
        redirect: '/products/carbon-steel-pipes-and-tubes'
    },
    {
        productImg: secondProduct,
        title: 'Stainless Steel Pipes & Tubes',
        subTitle: 'Stainless Steel Pipes & Tubes are made with precision and high quality materials.',
        redirect: '/products/stainless-steel-pipes-and-tubes'
    },
    {
        productImg: thirdProduct,
        title: 'Buttweld Fittings',
        subTitle: 'Flo Pipes & Fittings offers a wide range of Buttweld Fittings in Stainless Steel, and other grades and standards',
        redirect: '/products/buttweld-fittings'
    },
    {
        productImg: fourthProduct,
        title: 'Socketweld Fittings',
        subTitle: 'Flo Pipes & Fittings provides a wide range of Socketweld Fittings available in stainless steel and other grades.',
        redirect: '/products/socketweld-fittings'
    },
    {
        productImg: fifthProduct,
        title: 'Flanges',
        subTitle: 'Flanges are used to join pipes so that they may reach a greater distance and provide liquids or gases more easily.',
        redirect: '/products/flanges'
    },
    {
        productImg: sixthProduct,
        title: 'Sheet, Plate, Coils',
        subTitle: 'Sheet, Plate and Coils are manufactured by Flo Pipes & Fittings with high quality materials as per ASTM Standard.',
        redirect: '/products/sheet-plate-and-coils'
    },
    {
        productImg: seventhProduct,
        title: 'Flats, Angle, Channel & Beam',
        subTitle: 'Flats, Angle, Channel & Beam are manufactured by Flo Pipes & Fittings with high quality materials.',
        redirect: '/products/flats-angle-channel-beam'
    },
    {
        productImg: eighthProduct,
        title: 'Round Bar',
        subTitle: 'Round Bar are manufactured & supplier by Flo Pipes & Fittings with high quality materials as per standard.',
        redirect: '/products/round-bar'
    },
    {
        productImg: ninthProduct,
        title: 'Olets',
        subTitle: 'Olets are manufactured by Flo Pipes & Fittings with high quality materials.',
        redirect: '/products/olets'
    },
    {
        productImg: tenthProduct,
        title: 'Brass Rods',
        subTitle: 'Brass Rods are manufactured by Flo Pipes & Fittings with high quality materials.',
        redirect: '/products/brass-rods'
    },
    {
        productImg: eleventhProduct,
        title: 'Copper Tubes',
        subTitle: 'Copper Tubes are manufactured by Flo Pipes & Fittings with high quality materials.',
        redirect: '/products/copper-tubes'
    },
    {
        productImg: twelvethProduct,
        title: 'Fastener',
        subTitle: 'Flometal Pipes & Fittings is a leading Fasteners Manufacturer, Supplier & Stockist in India. we supplies bolt, nuts, and washer etc.',
        redirect: '/products/fastner'
    }
]

const Products = () => {
    
    return (<div style={{ backgroundColor: '#ffffff00' }}>
        <PageHeader title='Our Products' />
        <Row>
            <Col xs={24} xl={0}><br /></Col>
            <Col xs={0} xl={24}><br /><br /></Col>
        </Row>
        <ProductInfo title='OUR PRODUCTS' description={`The world's leading steel products suppliers from Mumbai, India.`} />
        <div className="all-products-card">
            <Row gutter={[48, 36]} className='col3'>
                {ProductList.map(entity => <Col xs={24} md={8} xxl={6}>
                    <ProductCard productImg={entity.productImg} title={entity.title} subTitle={entity.subTitle} redirect={entity.redirect} />
                </Col>)}
            </Row>
        </div>
    </div>)
}
export default Products;