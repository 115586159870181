import './Header.css'
import { WhatsAppOutlined, MailFilled, MobileFilled, PhoneFilled, MenuOutlined, DownOutlined ,DownloadOutlined} from '@ant-design/icons';
import { Col, Row ,Button} from 'antd';
import wlogo from '../img/wlogo.png';

const Header = () => {

const fetchFile=(file)=>{
    fetch(file, {
        method: "GET",
        headers: {
          Accept: "application/pdf",
          "Content-Type": "application/pdf",
        },
    
      }).then(response => response.blob())
        .then(response => {
      var blob=response
      var reader = new window.FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function() {
      var base64data = reader.result;
    
          window.open(base64data);
    
      }
    })
    .catch(error => {
      console.error(error);
    });
}

    return (<div className='header'>
        <div className='contact-info'>
            <Row align='middle' justify='center'>
                <Col md={2} lg={2} xl={2} xxl={2}></Col>
                <Col md={11} lg={12} xl={12} xxl={11}>
                    <Row gutter={16} justify='space-evenly'>
                        <Col md={8} lg={7}>
                            <a href="mailto:sales@flometal.com"><MailFilled className='icon' />sales@flometal.com</a>
                        </Col>
                        <Col md={8} lg={7}>
                            <a href="mailto:info@flometal.com"><MailFilled className='icon' />info@flometal.com</a>
                        </Col>
                        {/* <Col md={7} lg={7}>
                            <a href="tel:919820810810"><MobileFilled className='icon' />+91 9820810810</a>
                        </Col> */}
                        <Col md={8} lg={7}>
                            <a href="tel:919820810810"><PhoneFilled className='icon' />+91 9820810810</a>
                        </Col>
                    </Row>
                </Col>
                <Col md={2} lg={2} xl={2} xxl={3}>
                </Col>
                <Col md={4} lg={4} xl={4} xxl={4} className="get-whatsapp-quotes">
                    <a href="https://wa.me/919820810810" target='_blank'><WhatsAppOutlined /> Get Whatsapp Quotes</a>
                </Col>
                <Col md={4} lg={4} xl={4} xxl={4} className="get-whatsapp-quotes" style={{transform: 'scale(0.9)'}}>
                <a  onClick={()=>window.open('https://drive.google.com/file/d/1cZqcpay7L3IRUM-K0vfFIxv6pAeSDpwu/view?usp=sharing', 'Download')} target='_blank'><DownloadOutlined /> Download Catalog</a>
                </Col>
            </Row>
        </div>
        <div className='nav-bar'>
            <div></div>
            <div style={{ width: '100%' }} className="header_icon"><a href="/"><img src={wlogo} width="100%" alt='' /></a></div>
            <div style={{ marginBottom: '10px' }}>
                <input type="checkbox" id="menu-bar"></input> 
                <label htmlFor="menu-bar"> <MenuOutlined /></label>
                <nav>
                    <ul>
                        <li key='home'>
                            <a href="/">HOME</a>
                        </li>
                        <li key='aboutus'>
                            <a href="/aboutus">ABOUT US</a>
                        </li>
                        <li key='product'>
                            <a>PRODUCTS <DownOutlined /></a>
                            <ul>
                                <li key='p1'>
                                    <a href="/products/carbon-steel-pipes-and-tubes">Carbon Steel Pipes & Tubes</a>
                                </li>
                                <li key='p2'>
                                    <a href="/products/stainless-steel-pipes-and-tubes">Stainless Steel Pipes & Tubes</a>
                                </li>
                                <li key='p3'>
                                    <a href="/products/buttweld-fittings">Buttweld Fittings</a>
                                </li>
                                <li key='p4'>
                                    <a href="/products/socketweld-fittings">Socketweld Fittings</a>
                                </li>
                                <li key='p5'>
                                    <a href='/products/flanges'>Flanges</a>
                                </li>
                                <li key='p6'>
                                    <a href='/products/sheet-plate-and-coils'>Sheet, Plate & Coils</a>
                                </li>
                                <li key='p7'><a href="/products/flats-angle-channel-beam">Flats, Angle, Channel & Beam</a></li>
                                <li key='p8'><a href="/products/round-bar">Round Bar</a></li>
                                <li key='p9'><a href='/products/olets'>Olets</a></li>
                                <li key='p10'><a href='/products/brass-rods'>Brass Rods</a></li>
                                <li key='p11'><a href='/products/copper-tubes'>Copper Tubes</a></li>
                                <li key='p13'><a href='/products/fastner'>Fastener</a></li>
                                <li key='p14'>
                                    <a href="/products/all-products">All Products</a>
                                </li>
                            </ul>
                        </li>
                        <li key='techinfo'>
                            <a href="/technical-info">TECHNICAL INFO</a>
                        </li>
                        <li key='policy'>
                            <a href="/quality-policy">QUALITY POLICY</a>
                        </li>
                        <li key='enquiry'>
                            <a href="/enquiry">ENQUIRY</a>
                        </li>
                        <li key='contact'>
                            <a href="/contact">CONTACT</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>)
}
export default Header;