import PageHeader from "../../PageHeader";
import './TechnicalInformation.css';
import '../Pages.css';
import qualitypolicybg from '../../img/qualitypolicybg.jpg';
import ReactGA from 'react-ga';
import { useEffect } from 'react';
const TechnicalInformation = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    }, [])
    return (<div>
        <PageHeader title='Technical Information' background={qualitypolicybg}/>
        <div className="technical-info">
            <div className="technical-info-title">Stainless Steel Chemical Composition</div>
            <div className="technical_conditions_description">
                <div className="table-responsive">
                    <table className='technical-info-description'>
                        <tbody>
                            <tr>
                                <th>Grade</th>
                                <th>C (Max)</th>
                                <th>Mn (Max)</th>
                                <th>P (Max)</th>
                                <th>S (Max)</th>
                                <th>Si (Max)</th>
                                <th>Cr</th>
                                <th>Ni</th>
                                <th>Mo</th>
                                <th>Nitrogen (Max)</th>
                                <th>Cu/ Others</th>
                            </tr>
                            <tr>
                                <td>201</td>
                                <td>0.15</td>
                                <td>5.50/7.50</td>
                                <td>0.06</td>
                                <td>0.030</td>
                                <td>1.0</td>
                                <td>16.0/18.0</td>
                                <td>3.5/5.5</td>
                                <td>-</td>
                                <td>-</td>
                                <td>n-25 max</td>
                            </tr>
                            <tr>
                                <td>202</td>
                                <td>0.15</td>
                                <td>7.50/10.0</td>
                                <td>0.06</td>
                                <td>0.030.</td>
                                <td>1.0</td>
                                <td>17.0/19.0</td>
                                <td>4.0/6.0</td>
                                <td>-</td>
                                <td>-</td>
                                <td>n-25 max</td>
                            </tr>
                            <tr>
                                <td>JS- 203</td>
                                <td>0.08</td>
                                <td>9.2510.25</td>
                                <td>0.070</td>
                                <td>0.030</td>
                                <td>0.75</td>
                                <td>14.25 - 15.25</td>
                                <td>2.25 - 2.75</td>
                                <td>-</td>
                                <td>-</td>
                                <td>1.60- 2.0</td>
                            </tr>
                            <tr>
                                <td>301</td>
                                <td>0.15</td>
                                <td>2.00</td>
                                <td>0.045</td>
                                <td>0.030</td>
                                <td>1.00</td>
                                <td>16.00 - 18.00</td>
                                <td>6.00 - 8.00</td>
                                <td>-</td>
                                <td>0.10</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>301M</td>
                                <td>0.10</td>
                                <td>4.55.5</td>
                                <td>0.060</td>
                                <td>0.030</td>
                                <td>0.75</td>
                                <td>14.50 - 15.50</td>
                                <td>6.0 - 7.0</td>
                                <td>-</td>
                                <td>-</td>
                                <td>1.70- 1.90</td>
                            </tr>
                            <tr>
                                <td>304</td>
                                <td>0.08</td>
                                <td>2.00</td>
                                <td>0.045</td>
                                <td>0.030</td>
                                <td>0.75</td>
                                <td>18.00 - 20.00</td>
                                <td>8.00- 10.50</td>
                                <td>-</td>
                                <td>0.10</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>304L</td>
                                <td>0.030</td>
                                <td>2.00</td>
                                <td>0.045</td>
                                <td>0.030</td>
                                <td>0.75</td>
                                <td>18.00 - 20.00</td>
                                <td>8.00- 12.00</td>
                                <td>-</td>
                                <td>0.10</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>309s</td>
                                <td>0.08</td>
                                <td>2.0</td>
                                <td>0.045</td>
                                <td>0.030</td>
                                <td>1.0</td>
                                <td>22.0/24.0</td>
                                <td>12.0/15.0</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>310</td>
                                <td>0.25</td>
                                <td>2.0</td>
                                <td>0.045</td>
                                <td>0.030</td>
                                <td>1.50</td>
                                <td>24.0/26.0</td>
                                <td>19.0/22.0</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>310S</td>
                                <td>0.08</td>
                                <td>2.00</td>
                                <td>0.045</td>
                                <td>0.030</td>
                                <td>1.50</td>
                                <td>24.00- 26.00</td>
                                <td>19.00 - 22.00</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>316</td>
                                <td>0.08</td>
                                <td>2.00</td>
                                <td>0.045</td>
                                <td>0.030</td>
                                <td>0.75</td>
                                <td>16.00 - 18.00</td>
                                <td>10.00 - 14.00</td>
                                <td>2.00 - 3.00</td>
                                <td>0.10</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>316L</td>
                                <td>0.030</td>
                                <td>2.00</td>
                                <td>0.045</td>
                                <td>0.030</td>
                                <td>0.75</td>
                                <td>16.00 - 18.00</td>
                                <td>10.00 - 14.00</td>
                                <td>2.00 - 3.00</td>
                                <td>0.10</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>317</td>
                                <td>0.08</td>
                                <td>2.00</td>
                                <td>0.045</td>
                                <td>0.030</td>
                                <td>0.75</td>
                                <td>18.00 - 20.00</td>
                                <td>11.00 - 14.00</td>
                                <td>3.00 - 4.00</td>
                                <td>0.10</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>317L</td>
                                <td>0.030</td>
                                <td>2.00</td>
                                <td>0.045</td>
                                <td>0.030</td>
                                <td>0.75</td>
                                <td>18.00 - 20.00</td>
                                <td>11.00 - 15.00</td>
                                <td>3.00 - 4.00</td>
                                <td>0.10</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>321</td>
                                <td>0.08</td>
                                <td>2.00</td>
                                <td>0.045</td>
                                <td>0.030</td>
                                <td>0.75</td>
                                <td>17.00 - 19.00</td>
                                <td>9.00 - 12.00</td>
                                <td>-</td>
                                <td>0.10</td>
                                <td>Ti5 ( C + N ) Min or 0.70 max</td>
                            </tr>
                            <tr>
                                <td>347</td>
                                <td>0.08</td>
                                <td>2.00</td>
                                <td>0.045</td>
                                <td>0.030</td>
                                <td>0.75</td>
                                <td>17.00 - 19.00</td>
                                <td>9.00 - 13.00</td>
                                <td>-</td>
                                <td>-</td>
                                <td>Cb= 10x ( C Min ) or 1.00 Max</td>
                            </tr>
                            <tr>
                                <td>409</td>
                                <td>0.08</td>
                                <td>1.00</td>
                                <td>0.040</td>
                                <td>0.010</td>
                                <td>1.00</td>
                                <td>10.50 - 11.75</td>
                                <td>0.50</td>
                                <td>-</td>
                                <td>-</td>
                                <td>Ti= 6x (C+ N ) Min or 0.70 Max</td>
                            </tr>
                            <tr>
                                <td>409M</td>
                                <td>0.03</td>
                                <td>0.81.2</td>
                                <td>0.030</td>
                                <td>0.030</td>
                                <td>0.40.75</td>
                                <td>11.00- 12.00</td>
                                <td>1.5 max.</td>
                                <td>-</td>
                                <td>-</td>
                                <td>Ti= 6x (C) Min or 0.70 Max</td>
                            </tr>
                            <tr>
                                <td>410S</td>
                                <td>0.08</td>
                                <td>1.00</td>
                                <td>0.040</td>
                                <td>0.030</td>
                                <td>1.00</td>
                                <td>11.50- 13.50</td>
                                <td>0.60</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>410</td>
                                <td>0.15</td>
                                <td>1.00</td>
                                <td>0.040</td>
                                <td>0.030</td>
                                <td>1.00</td>
                                <td>11.50- 13.50</td>
                                <td>0.75</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>420</td>
                                <td>0.35</td>
                                <td>0.50</td>
                                <td>0.035</td>
                                <td>0.015</td>
                                <td>0.50</td>
                                <td>12.00 - 13.00</td>
                                <td>0.20.3</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>440A</td>
                                <td>0.60/0.70</td>
                                <td>1.0</td>
                                <td>0.040</td>
                                <td>0.030</td>
                                <td>1.0</td>
                                <td>16.0/18.0</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>440B</td>
                                <td>0.75,0.95</td>
                                <td>1.0</td>
                                <td>0.040</td>
                                <td>0.030</td>
                                <td>1.0</td>
                                <td>16.0/18.0</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>440C</td>
                                <td>0.95, 1.2</td>
                                <td>1.0</td>
                                <td>0.040</td>
                                <td>0.030</td>
                                <td>1.0</td>
                                <td>16.0/18.0</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>430</td>
                                <td>0.12</td>
                                <td>1.00</td>
                                <td>0.040</td>
                                <td>0.030</td>
                                <td>1.00</td>
                                <td>16.00 - 18.00</td>
                                <td>0.75</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>JSL AUS</td>
                                <td>0.08</td>
                                <td>7.08.0</td>
                                <td>0.070</td>
                                <td>0.030</td>
                                <td>0.75</td>
                                <td>15.50 - 16.50</td>
                                <td>4.25 - 4.75</td>
                                <td>-</td>
                                <td>-</td>
                                <td>0.9 - 1.10</td>
                            </tr>
                            <tr>
                                <td>904L</td>
                                <td>0.02</td>
                                <td>2.00</td>
                                <td>0.030</td>
                                <td>0.045</td>
                                <td>1.00</td>
                                <td>19.0-23.0</td>
                                <td>23.0-28.0</td>
                                <td>-</td>
                                <td>-</td>
                                <td>23.0-28.0</td>
                            </tr>
                            <tr>
                                <td>2205</td>
                                <td>0.03</td>
                                <td>2.00</td>
                                <td>0.030</td>
                                <td>0.020</td>
                                <td>1.00</td>
                                <td>21.0-23.0</td>
                                <td>4.5-6.5</td>
                                <td>4.5-6.5</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>2207</td>
                                <td>0.030</td>
                                <td>1.20</td>
                                <td>0.035</td>
                                <td>0.020</td>
                                <td>0.30</td>
                                <td>24.0-26.0</td>
                                <td>6.0-8.0</td>
                                <td>-</td>
                                <td>-</td>
                                <td>6.0-8.0</td>
                            </tr>
                            <tr>
                                <td colSpan="11">* Thickness of 1.27mm &amp; below will have elongation of 20% min.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <br />
            <div className="technical-info-title">Stainless Steel Mechanical Properties</div>
            <div className="technical_conditions_description">
                <div className="table-responsive">
                    <table className='technical-info-description'>
                        <tbody>
                            <tr>
                                <th colSpan="6">MECHANICAL PROPERTIES</th>
                            </tr>
                            <tr>
                                <td>Grade</td>
                                <td>Tensile</td>
                                <td>Yield</td>
                                <td>%Age</td>
                                <td>Hardness (Max)</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>-</td>
                                <td>Strength Mpa, (Min)</td>
                                <td>Strength</td>
                                <td>Elongation</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>-</td>
                                <td>-</td>
                                <td>Mpa, (Min)</td>
                                <td>in 50mm</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>gauge length min</td>
                                <td>BHN</td>
                                <td>Rb</td>
                            </tr>
                            <tr>
                                <td>301</td>
                                <td>515</td>
                                <td>205</td>
                                <td>40</td>
                                <td>217</td>
                                <td>95</td>
                            </tr>
                            <tr>
                                <td>304</td>
                                <td>515</td>
                                <td>205</td>
                                <td>40</td>
                                <td>201</td>
                                <td>92</td>
                            </tr>
                            <tr>
                                <td>304L</td>
                                <td>485</td>
                                <td>170</td>
                                <td>40</td>
                                <td>201</td>
                                <td>92</td>
                            </tr>
                            <tr>
                                <td>310S</td>
                                <td>515</td>
                                <td>205</td>
                                <td>40</td>
                                <td>217</td>
                                <td>95</td>
                            </tr>
                            <tr>
                                <td>316</td>
                                <td>515</td>
                                <td>205</td>
                                <td>40</td>
                                <td>217</td>
                                <td>95</td>
                            </tr>
                            <tr>
                                <td>316L</td>
                                <td>485</td>
                                <td>170</td>
                                <td>40</td>
                                <td>217</td>
                                <td>95</td>
                            </tr>
                            <tr>
                                <td>317</td>
                                <td>515</td>
                                <td>205</td>
                                <td>35</td>
                                <td>217</td>
                                <td>95</td>
                            </tr>
                            <tr>
                                <td>317L</td>
                                <td>515</td>
                                <td>205</td>
                                <td>40</td>
                                <td>217</td>
                                <td>95</td>
                            </tr>
                            <tr>
                                <td>321</td>
                                <td>515</td>
                                <td>205</td>
                                <td>40</td>
                                <td>217</td>
                                <td>95</td>
                            </tr>
                            <tr>
                                <td>347</td>
                                <td>515</td>
                                <td>205</td>
                                <td>40</td>
                                <td>201</td>
                                <td>92</td>
                            </tr>
                            <tr>
                                <td>409</td>
                                <td>380</td>
                                <td>170</td>
                                <td>20</td>
                                <td>179</td>
                                <td>88</td>
                            </tr>
                            <tr>
                                <td>409M</td>
                                <td>430</td>
                                <td>275</td>
                                <td>20</td>
                                <td>187</td>
                                <td>90</td>
                            </tr>
                            <tr>
                                <td>410S</td>
                                <td>415</td>
                                <td>205</td>
                                <td>22</td>
                                <td>183</td>
                                <td>89</td>
                            </tr>
                            <tr>
                                <td>410</td>
                                <td>450</td>
                                <td>205</td>
                                <td>20</td>
                                <td>217</td>
                                <td>89</td>
                            </tr>
                            <tr>
                                <td>420</td>
                                <td>700( max )</td>
                                <td>-</td>
                                <td>15</td>
                                <td>217</td>
                                <td>95</td>
                            </tr>
                            <tr>
                                <td>430</td>
                                <td>450</td>
                                <td>205</td>
                                <td>22</td>
                                <td>183</td>
                                <td>89</td>
                            </tr>
                            <tr>
                                <td>JSL AUS</td>
                                <td>515</td>
                                <td>205</td>
                                <td>40</td>
                                <td>217</td>
                                <td>95</td>
                            </tr>
                            <tr>
                                <td>JS- 203</td>
                                <td>515</td>
                                <td>205</td>
                                <td>40</td>
                                <td>217</td>
                                <td>95</td>
                            </tr>
                            <tr>
                                <td>301M</td>
                                <td>515</td>
                                <td>205</td>
                                <td>40</td>
                                <td>217</td>
                                <td>95</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <br />
            <div className="technical-info-title">Nickel Alloy Chemical Composition</div>
            <div className="technical_conditions_description">
                <div className="table-responsive">
                    <table className='technical-info-description'>
                        <tbody>
                            <tr>
                                <th colSpan="14">U.S.A. / GROSSBRITAINNIE- U.S.A. / GRANDE-BRETAGNE-- U.S.A. / GREAT BRITAIN</th>
                            </tr>
                            <tr>
                                <td>Handelsbezeichung</td>
                                <td>C%</td>
                                <td>Co%</td>
                                <td>Cr%</td>
                                <td>Mo%</td>
                                <td>Ni%</td>
                                <td>V%</td>
                                <td>W%</td>
                                <td>Ai%</td>
                                <td>Cu%</td>
                                <td>Nb/Cb Ta%</td>
                                <td>Ti%</td>
                                <td>Fe%</td>
                                <td>Sonstige Autres-Other%</td>
                            </tr>
                            <tr>
                                <td>Designation Comercial</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>Comercial designation</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>Monel 400</td>
                                <td>0.12</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>65</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>32</td>
                                <td>-</td>
                                <td>-</td>
                                <td>1.5</td>
                                <td>Mn 1.0</td>
                            </tr>
                            <tr>
                                <td>Monel 401</td>
                                <td>0.1</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>43</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>53</td>
                                <td>-</td>
                                <td>-</td>
                                <td>0.75</td>
                                <td>Si 0.25; Mn 2.25</td>
                            </tr>
                            <tr>
                                <td>Monel 404</td>
                                <td>0.15</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>52.0-57.0</td>
                                <td>-</td>
                                <td>-</td>
                                <td>0.05</td>
                                <td>rest/bal</td>
                                <td>-</td>
                                <td>-</td>
                                <td>0.5</td>
                                <td>Mn 0.10; Si 0.10;S o.024</td>
                            </tr>
                            <tr>
                                <td>Monel 502</td>
                                <td>0.1</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>63.0-17.0</td>
                                <td>-</td>
                                <td>-</td>
                                <td>2.5-3.5</td>
                                <td>rest/bal</td>
                                <td>-</td>
                                <td>0.5</td>
                                <td>2</td>
                                <td>Mn 1.5;Si 0.5; S 0.010</td>
                            </tr>
                            <tr>
                                <td>Monel K 500</td>
                                <td>0.13</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>64</td>
                                <td>-</td>
                                <td>-</td>
                                <td>2.8</td>
                                <td>30</td>
                                <td>-</td>
                                <td>0.6</td>
                                <td>1</td>
                                <td>Mn 0.8</td>
                            </tr>
                            <tr>
                                <td>Monel R 405</td>
                                <td>0.15</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>66</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>31</td>
                                <td>-</td>
                                <td>-</td>
                                <td>1.2</td>
                                <td>Mn 1.0; S 0.04</td>
                            </tr>
                            <tr>
                                <td>Hastelloy B</td>
                                <td>0.1</td>
                                <td>1.25</td>
                                <td>0.6</td>
                                <td>28</td>
                                <td>rest/bal</td>
                                <td>0.3</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>5.5</td>
                                <td>Mn 0.80; Si 0.70</td>
                            </tr>
                            <tr>
                                <td>Hastelloy B2</td>
                                <td>0.02</td>
                                <td>1</td>
                                <td>1</td>
                                <td>26.0-30.0</td>
                                <td>rest/bal</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>2</td>
                                <td>Mn 1.0: Si 0.10</td>
                            </tr>
                            <tr>
                                <td>Hastelloy C</td>
                                <td>0.07</td>
                                <td>1.25</td>
                                <td>16</td>
                                <td>17</td>
                                <td>rest/bal</td>
                                <td>0.3</td>
                                <td>40</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>5.75</td>
                                <td>Mn 1.0 :Si 0 0.70</td>
                            </tr>
                            <tr>
                                <td>Hastelloy C4</td>
                                <td>0.015</td>
                                <td>2</td>
                                <td>14.0-18.0</td>
                                <td>14.0-17.0</td>
                                <td>rest/bal</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>0..70</td>
                                <td>3</td>
                                <td>Mn 1.0 ; Si 0.08</td>
                            </tr>
                            <tr>
                                <td>Hastelloy- C 276</td>
                                <td>0.02</td>
                                <td>2.5</td>
                                <td>14.0-16.5</td>
                                <td>15.0-17.0</td>
                                <td>rest/bal</td>
                                <td>0.35</td>
                                <td>3.0-4.5</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>4.0-7.0</td>
                                <td>Mn 1.0;Si 0.05</td>
                            </tr>
                            <tr>
                                <td>Hastelloy F</td>
                                <td>0.02</td>
                                <td>1.25</td>
                                <td>22</td>
                                <td>6.5</td>
                                <td>rest/bal</td>
                                <td>-</td>
                                <td>0.5</td>
                                <td>-</td>
                                <td>-</td>
                                <td>Nb2.10</td>
                                <td>-</td>
                                <td>21</td>
                                <td>Mn 1.50;Si 0.50</td>
                            </tr>
                            <tr>
                                <td>Hastelloy G</td>
                                <td>0.05</td>
                                <td>2.5</td>
                                <td>21.0-23.5</td>
                                <td>5.5-7.5</td>
                                <td>rest/bal</td>
                                <td>-</td>
                                <td>1</td>
                                <td>-</td>
                                <td>1.5-2.5</td>
                                <td>Nb1.75-2.5</td>
                                <td>-</td>
                                <td>18.0-21.0</td>
                                <td>Mn 1.0-2.0;p0.04;Si 1.0;80.03</td>
                            </tr>
                            <tr>
                                <td>Hastelloy G-2</td>
                                <td>0.03</td>
                                <td>-</td>
                                <td>23.0-26.0</td>
                                <td>5.0-7.0</td>
                                <td>47.0-52.0</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>0.70-1.20</td>
                                <td>-</td>
                                <td>0.70-1.50</td>
                                <td>rest/bal</td>
                                <td>Mn 1.0;Si 1.0</td>
                            </tr>
                            <tr>
                                <td>Hastelloy N</td>
                                <td>0.06</td>
                                <td>0.25</td>
                                <td>7</td>
                                <td>16.5</td>
                                <td>rest/bal</td>
                                <td>-</td>
                                <td>0.2</td>
                                <td>-</td>
                                <td>0.1</td>
                                <td>-</td>
                                <td>-</td>
                                <td>3</td>
                                <td>Mn 0.40;Si 0.25;B0.01</td>
                            </tr>
                            <tr>
                                <td>Hastelloy S</td>
                                <td>0.02</td>
                                <td>2</td>
                                <td>15.5</td>
                                <td>14.5</td>
                                <td>rest/bal</td>
                                <td>0.6</td>
                                <td>1</td>
                                <td>0.2</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>3</td>
                                <td>Mn 0.50;Si 0.40;B0.0009;LA 0.02</td>
                            </tr>
                            <tr>
                                <td>Hastelloy W</td>
                                <td>0.06</td>
                                <td>1.25</td>
                                <td>5</td>
                                <td>24.5</td>
                                <td>rest/bal</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>5.5</td>
                                <td>Mn 0.050 ; Si 0.50</td>
                            </tr>
                            <tr>
                                <td>Hastelloy X</td>
                                <td>0.1</td>
                                <td>1.5</td>
                                <td>22</td>
                                <td>9</td>
                                <td>rest/bal</td>
                                <td>-</td>
                                <td>0.6</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>18.5</td>
                                <td>-</td>
                                <td>Mn 0.6; Si 0.60</td>
                            </tr>
                            <tr>
                                <td>Incoloy 800</td>
                                <td>0.04</td>
                                <td>-</td>
                                <td>21</td>
                                <td>-</td>
                                <td>32</td>
                                <td>-</td>
                                <td>-</td>
                                <td>0.3</td>
                                <td>-</td>
                                <td>-</td>
                                <td>0.4</td>
                                <td>45</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>Incoloy 800 H</td>
                                <td>0.08</td>
                                <td>-</td>
                                <td>21</td>
                                <td>-</td>
                                <td>32</td>
                                <td>-</td>
                                <td>-</td>
                                <td>0.3</td>
                                <td>-</td>
                                <td>-</td>
                                <td>0.4</td>
                                <td>45</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>Incoloy 801</td>
                                <td>0.05</td>
                                <td>-</td>
                                <td>20.5</td>
                                <td>-</td>
                                <td>32</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>1.1</td>
                                <td>45</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>Incoloy 802</td>
                                <td>0.35</td>
                                <td>-</td>
                                <td>21</td>
                                <td>-</td>
                                <td>32</td>
                                <td>-</td>
                                <td>-</td>
                                <td>0.6</td>
                                <td>-</td>
                                <td>-</td>
                                <td>0.7</td>
                                <td>45</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>Incoloy 804</td>
                                <td>0.05</td>
                                <td>-</td>
                                <td>29.5</td>
                                <td>-</td>
                                <td>41</td>
                                <td>-</td>
                                <td>-</td>
                                <td>0.3</td>
                                <td>-</td>
                                <td>-</td>
                                <td>0.6</td>
                                <td>25.4</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>Incoloy 805</td>
                                <td>0.12</td>
                                <td>-</td>
                                <td>7.5</td>
                                <td>0.5</td>
                                <td>36</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>0.1</td>
                                <td>-</td>
                                <td>-</td>
                                <td>rest/bal</td>
                                <td>Mn 0.60;Si 0.50</td>
                            </tr>
                            <tr>
                                <td>Incoloy 810</td>
                                <td>0.25</td>
                                <td>-</td>
                                <td>21</td>
                                <td>-</td>
                                <td>32</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>0.5</td>
                                <td>-</td>
                                <td>-</td>
                                <td>rest/bal</td>
                                <td>Mn 0.90; Si 0.80</td>
                            </tr>
                            <tr>
                                <td>Incoloy 825</td>
                                <td>0.04</td>
                                <td>-</td>
                                <td>21</td>
                                <td>3</td>
                                <td>42</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>2</td>
                                <td>-</td>
                                <td>1</td>
                                <td>30</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>Incoloy 825 Cp</td>
                                <td>0.04</td>
                                <td>-</td>
                                <td>21.5</td>
                                <td>3</td>
                                <td>42</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>2.2</td>
                                <td>Nb0.9</td>
                                <td>-</td>
                                <td>30</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>Incoloy 840</td>
                                <td>0.08</td>
                                <td>-</td>
                                <td>20</td>
                                <td>-</td>
                                <td>20</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>rest/bal</td>
                                <td>Mn 1.0;Si 1.0</td>
                            </tr>
                            <tr>
                                <td>Incoloy 901</td>
                                <td>0.05</td>
                                <td>-</td>
                                <td>12.5</td>
                                <td>6</td>
                                <td>rest/bal</td>
                                <td>-</td>
                                <td>-</td>
                                <td>0.15</td>
                                <td>-</td>
                                <td>-</td>
                                <td>2.7</td>
                                <td>34</td>
                                <td>Mn 0.24;Si 0.12;B0.015</td>
                            </tr>
                            <tr>
                                <td>Incoloy 901 Mod</td>
                                <td>0.05</td>
                                <td>-</td>
                                <td>12.5</td>
                                <td>5.8</td>
                                <td>rest/bal</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>2.9</td>
                                <td>34</td>
                                <td>Mn 0.09;Si 0.08;B0.015</td>
                            </tr>
                            <tr>
                                <td>Incoloy 903</td>
                                <td>0.02</td>
                                <td>15</td>
                                <td>-</td>
                                <td>-</td>
                                <td>38</td>
                                <td>-</td>
                                <td>-</td>
                                <td>0.7</td>
                                <td>-</td>
                                <td>Nb 3.0</td>
                                <td>1.4</td>
                                <td>41</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>Incoloy 904</td>
                                <td>0.02</td>
                                <td>14</td>
                                <td>-</td>
                                <td>-</td>
                                <td>33</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>1.7</td>
                                <td>50</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>Incoloy DS</td>
                                <td>0.06</td>
                                <td>-</td>
                                <td>18</td>
                                <td>-</td>
                                <td>37</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>42</td>
                                <td>Mn 1.0; Si 2.3</td>
                            </tr>
                            <tr>
                                <td>Incoloy Ma- 956</td>
                                <td>-</td>
                                <td>-</td>
                                <td>20</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>4.5</td>
                                <td>-</td>
                                <td>-</td>
                                <td>0.5</td>
                                <td>74.4</td>
                                <td>Y2.03 0.5</td>
                            </tr>
                            <tr>
                                <td>Incoloy 600</td>
                                <td>0.05</td>
                                <td>-</td>
                                <td>15.5</td>
                                <td>-</td>
                                <td>75</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>8</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>Incoloy 601</td>
                                <td>0.05</td>
                                <td>-</td>
                                <td>23</td>
                                <td>-</td>
                                <td>60</td>
                                <td>-</td>
                                <td>-</td>
                                <td>1.4</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>14</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>Incoloy 604</td>
                                <td>0.04</td>
                                <td>-</td>
                                <td>15.8</td>
                                <td>-</td>
                                <td>rest/bal</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>0.1</td>
                                <td>Nb 2.0</td>
                                <td>-</td>
                                <td>7.2</td>
                                <td>Mn 0.20; Si 0.20</td>
                            </tr>
                            <tr>
                                <td>Incoloy 610</td>
                                <td>0.2</td>
                                <td>-</td>
                                <td>15.5</td>
                                <td>-</td>
                                <td>rest/bal</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>0.5</td>
                                <td>Nb 1.0</td>
                                <td>-</td>
                                <td>9</td>
                                <td>Mn 0.90;Si 2.0</td>
                            </tr>
                            <tr>
                                <td>Incoloy 617</td>
                                <td>0.07</td>
                                <td>12.5</td>
                                <td>22.5</td>
                                <td>9</td>
                                <td>54</td>
                                <td>-</td>
                                <td>-</td>
                                <td>1</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>Incoloy 625</td>
                                <td>0.05</td>
                                <td>-</td>
                                <td>21.5</td>
                                <td>9</td>
                                <td>61</td>
                                <td>-</td>
                                <td>-</td>
                                <td>0.4</td>
                                <td>-</td>
                                <td>Nb 3.65</td>
                                <td>0.4</td>
                                <td>2.5</td>
                                <td>Mn 0.50; Si 0.50</td>
                            </tr>
                            <tr>
                                <td>Incoloy 671</td>
                                <td>0.05</td>
                                <td>-</td>
                                <td>48</td>
                                <td>-</td>
                                <td>51</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>0.35</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>Incoloy 700</td>
                                <td>0.12</td>
                                <td>28.5</td>
                                <td>15</td>
                                <td>3.75</td>
                                <td>46</td>
                                <td>-</td>
                                <td>-</td>
                                <td>3</td>
                                <td>0.05</td>
                                <td>-</td>
                                <td>2.2</td>
                                <td>0.7</td>
                                <td>Mn 0.10; Si 0.30</td>
                            </tr>
                            <tr>
                                <td>Incoloy 702</td>
                                <td>0.04</td>
                                <td>-</td>
                                <td>15.6</td>
                                <td>-</td>
                                <td>rest/bal</td>
                                <td>-</td>
                                <td>-</td>
                                <td>3.4</td>
                                <td>0.1</td>
                                <td>-</td>
                                <td>0.7</td>
                                <td>0.35</td>
                                <td>Mn 0.05: Si 0.20</td>
                            </tr>
                            <tr>
                                <td>Incoloy 705</td>
                                <td>0.3</td>
                                <td>-</td>
                                <td>15.5</td>
                                <td>-</td>
                                <td>rest/bal</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>0.5</td>
                                <td>-</td>
                                <td>-</td>
                                <td>8</td>
                                <td>Mn 0.90 ; Si 5.5</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <br />
            <div className="technical-info-title">Carbon Steel Chemical Composition</div>
            <div className="technical_conditions_description">
                <div className="table-responsive">
                    <table className='technical-info-description' >
                        <tbody>
                            <tr>
                                <th rowSpan="4"><strong>Speci -cation</strong></th>
                                <th rowSpan="4"><strong>Grade</strong></th>
                                <th colSpan="15"><strong>Chemical Composition [%]</strong></th>
                                <th colSpan="6"><strong>Mechanical Properties</strong></th>
                            </tr>
                            <tr>
                                <th colSpan="2" rowSpan="2">C</th>
                                <th colSpan="2" rowSpan="2">Mn</th>
                                <th rowSpan="2">P</th>
                                <th rowSpan="2">S</th>
                                <th colSpan="2" rowSpan="2">Si</th>
                                <th colSpan="2" rowSpan="2">Cr</th>
                                <th colSpan="2" rowSpan="2">Mo</th>
                                <th rowSpan="2">Cu</th>
                                <th rowSpan="2">Ni</th>
                                <th rowSpan="2">V</th>
                                <th colSpan="2"><strong>Yield Strength</strong></th>
                                <th colSpan="3"><strong>Tensile Strength</strong></th>
                                <th rowSpan="3"><strong>% Elongation (G.L. 50 mm WT 8 mm) e (min.)</strong></th>
                            </tr>
                            <tr>
                                <th colSpan="2"><strong>Min.</strong></th>
                                <th colSpan="3"><strong>Min.</strong></th>
                            </tr>
                            <tr>
                                <th>Min</th>
                                <th>Max.</th>
                                <th>Min</th>
                                <th>Max.</th>
                                <th>Max.</th>
                                <th>Max.</th>
                                <th>Min.</th>
                                <th>Max</th>
                                <th>Min.</th>
                                <th>Max</th>
                                <th>Min.</th>
                                <th>Max</th>
                                <th>Max</th>
                                <th>Max</th>
                                <th>Max</th>
                                <th>PSI</th>
                                <th>MPa</th>
                                <th>PSI</th>
                                <th colSpan="2">MPa</th>
                            </tr>
                            <tr>
                                <th colSpan="23"><strong>HYDROCARBON PROCESS INDUSTRY/STRUCTURAL/PRESSURE VESSEL TUBES</strong></th>
                            </tr>
                            <tr>
                                <td>ASTM</td>
                                <td>A</td>
                                <td>-</td>
                                <td>0.25</td>
                                <td>-</td>
                                <td>0.95</td>
                                <td>0.05</td>
                                <td>0.045</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>0.4</td>
                                <td>-</td>
                                <td>0.15</td>
                                <td>0.40 for S</td>
                                <td>0.4</td>
                                <td>0.08</td>
                                <td>30,000</td>
                                <td>205</td>
                                <td>48,000</td>
                                <td>330</td>
                                <td colSpan="2" rowSpan="2">e=625000 A02/U09</td>
                            </tr>
                            <tr>
                                <td>A-53</td>
                                <td>B</td>
                                <td>-</td>
                                <td>0.3</td>
                                <td>-</td>
                                <td>1.2</td>
                                <td>0.05</td>
                                <td>0.045</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>0.4</td>
                                <td>-</td>
                                <td>0.15</td>
                                <td>0.40 for S</td>
                                <td>0.4</td>
                                <td>0.08</td>
                                <td>35,000</td>
                                <td>240</td>
                                <td>60,000</td>
                                <td>415</td>
                            </tr>
                            <tr>
                                <td>ASTM</td>
                                <td>A</td>
                                <td>-</td>
                                <td>0.25</td>
                                <td>0.27</td>
                                <td>0.93</td>
                                <td>0.035</td>
                                <td>0.035</td>
                                <td>0.1</td>
                                <td>-</td>
                                <td>-</td>
                                <td>0.4</td>
                                <td>-</td>
                                <td>0.15</td>
                                <td>0.4</td>
                                <td>0.4</td>
                                <td>0.08</td>
                                <td>30,000</td>
                                <td>205</td>
                                <td>48,000</td>
                                <td>330</td>
                                <td colSpan="2">35L / 25T</td>
                            </tr>
                            <tr>
                                <td>A-106</td>
                                <td>B</td>
                                <td>-</td>
                                <td>0.3</td>
                                <td>0.29</td>
                                <td>1.06</td>
                                <td>0.035</td>
                                <td>0.035</td>
                                <td>0.1</td>
                                <td>-</td>
                                <td>-</td>
                                <td>0.4</td>
                                <td>-</td>
                                <td>0.15</td>
                                <td>0.4</td>
                                <td>-</td>
                                <td>0.08</td>
                                <td>35,000</td>
                                <td>240</td>
                                <td>60,000</td>
                                <td>415</td>
                                <td colSpan="2">30L / 16.5T</td>
                            </tr>
                            <tr>
                                <td>-</td>
                                <td>c</td>
                                <td>-</td>
                                <td>0.35</td>
                                <td>0.29</td>
                                <td>1.06</td>
                                <td>0.035</td>
                                <td>0.035</td>
                                <td>0.1</td>
                                <td>-</td>
                                <td>-</td>
                                <td>0.4</td>
                                <td>-</td>
                                <td>0.15</td>
                                <td>0.4</td>
                                <td>-</td>
                                <td>0.08</td>
                                <td>40,000</td>
                                <td>275</td>
                                <td>70,000</td>
                                <td>485</td>
                                <td colSpan="2">30L / 16.5T</td>
                            </tr>
                            <tr>
                                <td>ASTM</td>
                                <td>1</td>
                                <td>-</td>
                                <td>0.3</td>
                                <td>0.4</td>
                                <td>1.06</td>
                                <td>0.025</td>
                                <td>0.025</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>30,000</td>
                                <td>205</td>
                                <td>55,000</td>
                                <td>380</td>
                                <td colSpan="2">35</td>
                            </tr>
                            <tr>
                                <td>A333 &amp; A 334</td>
                                <td>6</td>
                                <td>-</td>
                                <td>0.3</td>
                                <td>0.29</td>
                                <td>1.06</td>
                                <td>0.025</td>
                                <td>0.025</td>
                                <td>0.1</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>35,000</td>
                                <td>240</td>
                                <td>60,000</td>
                                <td>415</td>
                                <td colSpan="2">30</td>
                            </tr>
                            <tr>
                                <td colSpan="23"><strong>SEAMLESS COLD DRAWN PRECISION STEEL TUBES FOR MECHANICAL APPLICATIONS/HYDRAULIC/PNEUMATIC POWER SYSTEMS</strong></td>
                            </tr>
                            <tr>
                                <td>EN 10305-1/</td>
                                <td>E 235</td>
                                <td rowSpan="2">-</td>
                                <td>0.17</td>
                                <td rowSpan="2">-</td>
                                <td>1.2</td>
                                <td>0.025</td>
                                <td>0.025</td>
                                <td rowSpan="2">-</td>
                                <td>0.35</td>
                                <td rowSpan="2">-</td>
                                <td rowSpan="2">-</td>
                                <td rowSpan="2">-</td>
                                <td rowSpan="2">-</td>
                                <td rowSpan="2">-</td>
                                <td rowSpan="2">-</td>
                                <td>0.02</td>
                                <td rowSpan="2">-</td>
                                <td>235</td>
                                <td rowSpan="2">-</td>
                                <td>340</td>
                                <td colSpan="2">25</td>
                            </tr>
                            <tr>
                                <td>EN10305-4</td>
                                <td>E 355</td>
                                <td>0.22</td>
                                <td>1.6</td>
                                <td>0.025</td>
                                <td>0.025</td>
                                <td>0.55</td>
                                <td>0.02</td>
                                <td>355</td>
                                <td>490</td>
                                <td colSpan="2">22</td>
                            </tr>
                            <tr>
                                <td rowSpan="2">EN 10216-1</td>
                                <td>P195 TR1</td>
                                <td rowSpan="2">-</td>
                                <td>0.13</td>
                                <td rowSpan="2">-</td>
                                <td>0.7</td>
                                <td>0.025</td>
                                <td>0.02</td>
                                <td rowSpan="2">-</td>
                                <td>0.35</td>
                                <td rowSpan="2">-</td>
                                <td>0.3</td>
                                <td rowSpan="2">-</td>
                                <td>0.08</td>
                                <td>0.3</td>
                                <td>0.3</td>
                                <td rowSpan="2">-</td>
                                <td rowSpan="2">-</td>
                                <td>195</td>
                                <td rowSpan="2">-</td>
                                <td>320</td>
                                <td colSpan="2">27</td>
                            </tr>
                            <tr>
                                <td>P235 TR1</td>
                                <td>0.16</td>
                                <td>1.2</td>
                                <td>0.025</td>
                                <td>0.02</td>
                                <td>0.35</td>
                                <td>0.3</td>
                                <td>0.08</td>
                                <td>0.3</td>
                                <td>0.3</td>
                                <td>235</td>
                                <td>360</td>
                                <td colSpan="2">25</td>
                            </tr>
                            <tr>
                                <td>EN 10216-2</td>
                                <td>P235 GH</td>
                                <td>-</td>
                                <td>0.16</td>
                                <td>-</td>
                                <td>1.2</td>
                                <td>0.025</td>
                                <td>0.02</td>
                                <td>-</td>
                                <td>0.35</td>
                                <td>-</td>
                                <td>0.3</td>
                                <td>-</td>
                                <td>0.08</td>
                                <td>0.3</td>
                                <td>0.3</td>
                                <td>0.02</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td colSpan="2">-</td>
                            </tr>
                            <tr>
                                <td rowSpan="3">DIN 2391</td>
                                <td>St - 45</td>
                                <td>-</td>
                                <td>0.17</td>
                                <td>0.4</td>
                                <td>-</td>
                                <td>0.025</td>
                                <td>0.025</td>
                                <td>-</td>
                                <td>0.35</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td colSpan="2">NBK : 215 / GBK : -</td>
                                <td colSpan="2">NBK : 340 / GBK : 315</td>
                                <td colSpan="2">NBK : 470</td>
                            </tr>
                            <tr>
                                <td>St - 45</td>
                                <td>-</td>
                                <td>0.21</td>
                                <td>0.4</td>
                                <td>-</td>
                                <td>0.025</td>
                                <td>0.025</td>
                                <td>-</td>
                                <td>0.35</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td colSpan="2">NBK : 255 / GBK : -</td>
                                <td colSpan="2">NBK : 440 / GBK : 390</td>
                                <td colSpan="2">NBK : 570</td>
                            </tr>
                            <tr>
                                <td>St - 52</td>
                                <td>-</td>
                                <td>0.22</td>
                                <td>-</td>
                                <td>1.6</td>
                                <td>0.025</td>
                                <td>0.025</td>
                                <td>-</td>
                                <td>0.55</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td colSpan="2">NBK : 355 / GBK : -</td>
                                <td colSpan="2">NBK : 490 / GBK : 490</td>
                                <td colSpan="2">NBK : 630</td>
                            </tr>
                            <tr>
                                <td>ASTM</td>
                                <td>SAE 1010</td>
                                <td>0.08</td>
                                <td>0.13 0.20</td>
                                <td>0.3</td>
                                <td>0.6</td>
                                <td>0.04</td>
                                <td>0.05</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td colSpan="6" rowSpan="8">As per Customer requirements</td>
                            </tr>
                            <tr>
                                <td>A-519</td>
                                <td>SAE 1018</td>
                                <td>0.15</td>
                                <td>0.28</td>
                                <td>0.6</td>
                                <td>0.9</td>
                                <td>0.04</td>
                                <td>0.05</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>-</td>
                                <td>SAE 1026</td>
                                <td>0.22</td>
                                <td>0.38</td>
                                <td>0.6</td>
                                <td>0.9</td>
                                <td>0.04</td>
                                <td>0.05</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>-</td>
                                <td>SAE 1035</td>
                                <td>0.32</td>
                                <td>0.44</td>
                                <td>0.6</td>
                                <td>0.9</td>
                                <td>0.04</td>
                                <td>0.05</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>-</td>
                                <td>SAE 1040</td>
                                <td>0.37</td>
                                <td>0.21</td>
                                <td>0.6</td>
                                <td>0.9</td>
                                <td>0.04</td>
                                <td>0.05</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>-</td>
                                <td>SAE 1518</td>
                                <td>0.15</td>
                                <td>0.44</td>
                                <td>1.1</td>
                                <td>1.4</td>
                                <td>0.04</td>
                                <td>0.05</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>-</td>
                                <td>SAE 1541</td>
                                <td>0.36</td>
                                <td>0.33</td>
                                <td>1.35</td>
                                <td>1.65</td>
                                <td>0.04</td>
                                <td>0.05</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>-</td>
                                <td>SAE 4130</td>
                                <td>0.28</td>
                                <td>-</td>
                                <td>0.4</td>
                                <td>0.6</td>
                                <td>0.04</td>
                                <td>0.04</td>
                                <td>0.15</td>
                                <td>0.35</td>
                                <td>0.8</td>
                                <td>1.1</td>
                                <td>0.15</td>
                                <td>0.25</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td colSpan="23">BOILER/HEAT EXCHANGER/SUPERHEATER &amp; CONDENSER TUBES</td>
                            </tr>
                            <tr>
                                <td>ASTM A179</td>
                                <td>-</td>
                                <td>0.06</td>
                                <td>0.18</td>
                                <td>0.27</td>
                                <td>0.63</td>
                                <td>0.035</td>
                                <td>0.035</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>26,000</td>
                                <td>180</td>
                                <td>47,000</td>
                                <td>325</td>
                                <td colSpan="2">35</td>
                            </tr>
                            <tr>
                                <td>ASTM A192</td>
                                <td>-</td>
                                <td>0.06</td>
                                <td>0.18</td>
                                <td>0.27</td>
                                <td>0.63</td>
                                <td>0.035</td>
                                <td>0.035</td>
                                <td>-</td>
                                <td>0.25</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>26,000</td>
                                <td>180</td>
                                <td>47,000</td>
                                <td>325</td>
                                <td colSpan="2">35</td>
                            </tr>
                            <tr>
                                <td>ASTM</td>
                                <td>A-1</td>
                                <td>-</td>
                                <td>0.27</td>
                                <td>-</td>
                                <td>0.93</td>
                                <td>0.035</td>
                                <td>0.035</td>
                                <td>0.1</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>37,000</td>
                                <td>255</td>
                                <td>60,000</td>
                                <td>415</td>
                                <td colSpan="2">30</td>
                            </tr>
                            <tr>
                                <td>A 210</td>
                                <td>C</td>
                                <td>-</td>
                                <td>0.35</td>
                                <td>0.29</td>
                                <td>1.06</td>
                                <td>0.035</td>
                                <td>0.035</td>
                                <td>0.1</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>40,000</td>
                                <td>275</td>
                                <td>70,000</td>
                                <td>485</td>
                                <td colSpan="2">30</td>
                            </tr>
                            <tr>
                                <td>ASTM</td>
                                <td>T-11</td>
                                <td>0.05</td>
                                <td>0.15</td>
                                <td>0.3</td>
                                <td>0.6</td>
                                <td>0.025</td>
                                <td>0.025</td>
                                <td>0.5</td>
                                <td>1</td>
                                <td>1</td>
                                <td>1.5</td>
                                <td>0.44</td>
                                <td>0.65</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>30,000</td>
                                <td>205</td>
                                <td>60,000</td>
                                <td>415</td>
                                <td colSpan="2">30</td>
                            </tr>
                            <tr>
                                <td>A 213</td>
                                <td>T-12</td>
                                <td>0.05</td>
                                <td>0.15</td>
                                <td>0.3</td>
                                <td>0.61</td>
                                <td>0.025</td>
                                <td>0.025</td>
                                <td>-</td>
                                <td>0.5</td>
                                <td>0.8</td>
                                <td>1.25</td>
                                <td>0.44</td>
                                <td>0.65</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>32,000</td>
                                <td>220</td>
                                <td>60,000</td>
                                <td>415</td>
                                <td colSpan="2">30</td>
                            </tr>
                            <tr>
                                <td>-</td>
                                <td>T-22</td>
                                <td>0.05</td>
                                <td>0.15</td>
                                <td>0.3</td>
                                <td>0.6</td>
                                <td>0.025</td>
                                <td>0.025</td>
                                <td>-</td>
                                <td>0.5</td>
                                <td>1.9</td>
                                <td>2.6</td>
                                <td>0.87</td>
                                <td>1.13</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>30,000</td>
                                <td>205</td>
                                <td>60,000</td>
                                <td>415</td>
                                <td colSpan="2">30</td>
                            </tr>
                            <tr>
                                <td>ASTM</td>
                                <td>P-11</td>
                                <td>0.05</td>
                                <td>0.15</td>
                                <td>0.3</td>
                                <td>0.6</td>
                                <td>0.025</td>
                                <td>0.025</td>
                                <td>0.5</td>
                                <td>1</td>
                                <td>1</td>
                                <td>1.5</td>
                                <td>0.44</td>
                                <td>0.65</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>30,000</td>
                                <td>205</td>
                                <td>60,000</td>
                                <td>415</td>
                                <td colSpan="2">30</td>
                            </tr>
                            <tr>
                                <td>A335</td>
                                <td>P-12</td>
                                <td>0.05</td>
                                <td>0.15</td>
                                <td>0.3</td>
                                <td>0.61</td>
                                <td>0.025</td>
                                <td>0.025</td>
                                <td>-</td>
                                <td>0.5</td>
                                <td>0.8</td>
                                <td>1.25</td>
                                <td>0.44</td>
                                <td>0.65</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>32,000</td>
                                <td>220</td>
                                <td>60,000</td>
                                <td>415</td>
                                <td colSpan="2">30</td>
                            </tr>
                            <tr>
                                <td>-</td>
                                <td>P-22</td>
                                <td>0.05</td>
                                <td>0.15</td>
                                <td>0.3</td>
                                <td>0.6</td>
                                <td>0.025</td>
                                <td>0.025</td>
                                <td>-</td>
                                <td>0.5</td>
                                <td>1.9</td>
                                <td>2.6</td>
                                <td>0.87</td>
                                <td>1.13</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>30,000</td>
                                <td>205</td>
                                <td>60,000</td>
                                <td>415</td>
                                <td colSpan="2">30</td>
                            </tr>
                            <tr>
                                <td>BS 3059 Pt I</td>
                                <td>320</td>
                                <td>-</td>
                                <td>0.16</td>
                                <td>0.3</td>
                                <td>0.7</td>
                                <td>0.04</td>
                                <td>0.04</td>
                                <td>0.1</td>
                                <td>0.35</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>28,000</td>
                                <td>195</td>
                                <td>46,000</td>
                                <td>320</td>
                                <td colSpan="2">69,500</td>
                            </tr>
                            <tr>
                                <td>BS 3059 Pt II</td>
                                <td>622-490</td>
                                <td>0.08</td>
                                <td>0.15</td>
                                <td>0.4</td>
                                <td>0.7</td>
                                <td>0.03</td>
                                <td>0.03</td>
                                <td>-</td>
                                <td>0.5</td>
                                <td>2</td>
                                <td>2.5</td>
                                <td>0.9</td>
                                <td>1.2</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>40,000</td>
                                <td>275</td>
                                <td>71,000</td>
                                <td>490</td>
                                <td colSpan="2">93,000</td>
                            </tr>
                            <tr>
                                <td rowSpan="2">DIN 1629</td>
                                <td>St- 37.0</td>
                                <td>-</td>
                                <td>0.17</td>
                                <td>-</td>
                                <td>-</td>
                                <td>0.04</td>
                                <td>0.04</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td colSpan="2">WT 16mm = 235 / WT&gt;16mm = 225</td>
                                <td colSpan="2">350</td>
                                <td colSpan="2">-</td>
                            </tr>
                            <tr>
                                <td>St - 52</td>
                                <td>-</td>
                                <td>0.22</td>
                                <td>-</td>
                                <td>-</td>
                                <td>0.04</td>
                                <td>0.035</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td colSpan="2">WT 16mm = 355 / WT&gt;16mm = 345</td>
                                <td colSpan="2">-</td>
                                <td colSpan="2">Long 21 / Trans 19</td>
                            </tr>
                            <tr>
                                <td rowSpan="2">DIN 17175</td>
                                <td>St - 35.8</td>
                                <td>-</td>
                                <td>0.17</td>
                                <td>0.4</td>
                                <td>0.8</td>
                                <td>0.04</td>
                                <td>0.04</td>
                                <td>0.1</td>
                                <td>0.35</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td colSpan="2">WT 16mm = 235 / WT&gt;16mm = 225</td>
                                <td colSpan="2">360</td>
                                <td colSpan="2">480</td>
                            </tr>
                            <tr>
                                <td>St - 45.8</td>
                                <td>-</td>
                                <td>0.21</td>
                                <td>0.4</td>
                                <td>1.2</td>
                                <td>0.04</td>
                                <td>0.04</td>
                                <td>0.1</td>
                                <td>0.35</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td colSpan="2">WT 16mm = 255 / WT&gt;16mm = 245</td>
                                <td colSpan="2">410</td>
                                <td colSpan="2">530</td>
                            </tr>
                            <tr>
                                <td colSpan="23"><strong>RAILWAYS</strong></td>
                            </tr>
                            <tr>
                                <td>IS:1239 Pt I</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>0.05</td>
                                <td>0.05</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>46,500</td>
                                <td>320</td>
                                <td colSpan="2">-</td>
                            </tr>
                            <tr>
                                <td rowSpan="3">IS:1161</td>
                                <td>YST-210</td>
                                <td>-</td>
                                <td>0.12</td>
                                <td>-</td>
                                <td>0.6</td>
                                <td>0.04</td>
                                <td>0.04</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>30,500</td>
                                <td>210</td>
                                <td>48,000</td>
                                <td>330</td>
                                <td colSpan="2">-</td>
                            </tr>
                            <tr>
                                <td>YST-240</td>
                                <td>-</td>
                                <td>0.16</td>
                                <td>-</td>
                                <td>1.2</td>
                                <td>0.04</td>
                                <td>0.04</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>35,000</td>
                                <td>240</td>
                                <td>59,500</td>
                                <td>410</td>
                                <td colSpan="2">-</td>
                            </tr>
                            <tr>
                                <td>YST-310</td>
                                <td>-</td>
                                <td>0.25</td>
                                <td>-</td>
                                <td>1.3</td>
                                <td>0.04</td>
                                <td>0.04</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>45,000</td>
                                <td>310</td>
                                <td>64,500</td>
                                <td>445</td>
                                <td colSpan="2">-</td>
                            </tr>
                            <tr>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <br />
            <div className="technical-info-title">STAINLESS STEEL PIPE DIMENSION AS PER ASTM & WEIGHT-KG. PER MTR. (ANSI B36.19)</div>
            <div className="technical_conditions_description">
                <div className="table-responsive">
                    <table className='technical-info-description'>
                        <tbody>
                            <tr>
                                <th colSpan="2">Nominal Bore</th>
                                <th>Outside Diameter</th>
                                <th colSpan="2">Sch-5S</th>
                                <th colSpan="2">Sch-10S</th>
                                <th colSpan="2">Sch-40S</th>
                                <th colSpan="2">Sch-80S</th>
                                <th colSpan="2">Sch-160S</th>
                                <th colSpan="2">Sch-XXS</th>
                            </tr>
                            <tr>
                                <th rowSpan="2">mm</th>
                                <th rowSpan="2">INCH</th>
                                <th>mm</th>
                                <th>Wt</th>
                                <th>Weight</th>
                                <th>Wt</th>
                                <th>Weight</th>
                                <th>Wt</th>
                                <th>Weight</th>
                                <th>Wt</th>
                                <th>Weight</th>
                                <th>Wt</th>
                                <th>Weight</th>
                                <th>Wt</th>
                                <th>Weight</th>
                            </tr>
                            <tr>
                                <th>&nbsp;</th>
                                <th>mm</th>
                                <th>(Kg/mt)</th>
                                <th>mm</th>
                                <th>(Kg/mt)</th>
                                <th>mm</th>
                                <th>(Kg/mt)</th>
                                <th>mm</th>
                                <th>(Kg/mt)</th>
                                <th>mm</th>
                                <th>(Kg/mt)</th>
                                <th>mm</th>
                                <th>(Kg/mt)</th>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>43313</td>
                                <td>10.3</td>
                                <td>1.24</td>
                                <td>0.276</td>
                                <td>1.24</td>
                                <td>0.28</td>
                                <td>1.73</td>
                                <td>0.37</td>
                                <td>2.41</td>
                                <td>0.47</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>43191</td>
                                <td>13.7</td>
                                <td>1.24</td>
                                <td>0.39</td>
                                <td>1.65</td>
                                <td>0.49</td>
                                <td>2.24</td>
                                <td>0.631</td>
                                <td>3.02</td>
                                <td>0.8</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>10</td>
                                <td>43315</td>
                                <td>17.1</td>
                                <td>1.24</td>
                                <td>0.49</td>
                                <td>1.65</td>
                                <td>0.63</td>
                                <td>2.31</td>
                                <td>0.845</td>
                                <td>3.2</td>
                                <td>1.1</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>15</td>
                                <td>43132</td>
                                <td>21.3</td>
                                <td>1.65</td>
                                <td>0.8</td>
                                <td>2.11</td>
                                <td>1</td>
                                <td>2.77</td>
                                <td>1.27</td>
                                <td>3.75</td>
                                <td>1.62</td>
                                <td>4.75</td>
                                <td>1.94</td>
                                <td>7.47</td>
                                <td>2.55</td>
                            </tr>
                            <tr>
                                <td>20</td>
                                <td>43193</td>
                                <td>26.7</td>
                                <td>1.65</td>
                                <td>1.03</td>
                                <td>2.11</td>
                                <td>1.28</td>
                                <td>2.87</td>
                                <td>1.68</td>
                                <td>3.91</td>
                                <td>2.2</td>
                                <td>5.54</td>
                                <td>2.89</td>
                                <td>7.82</td>
                                <td>3.63</td>
                            </tr>
                            <tr>
                                <td>25</td>
                                <td>1</td>
                                <td>33.4</td>
                                <td>1.65</td>
                                <td>1.3</td>
                                <td>2.77</td>
                                <td>2.09</td>
                                <td>3.38</td>
                                <td>2.5</td>
                                <td>4.55</td>
                                <td>3.24</td>
                                <td>6.35</td>
                                <td>4.24</td>
                                <td>9.09</td>
                                <td>5.45</td>
                            </tr>
                            <tr>
                                <td>32</td>
                                <td>1.25</td>
                                <td>42.2</td>
                                <td>1.65</td>
                                <td>1.65</td>
                                <td>2.77</td>
                                <td>2.7</td>
                                <td>3.56</td>
                                <td>3.38</td>
                                <td>4.85</td>
                                <td>4.47</td>
                                <td>6.35</td>
                                <td>5.61</td>
                                <td>9.7</td>
                                <td>7.77</td>
                            </tr>
                            <tr>
                                <td>40</td>
                                <td>1.5</td>
                                <td>48.3</td>
                                <td>1.65</td>
                                <td>1.91</td>
                                <td>2.77</td>
                                <td>3.11</td>
                                <td>3.68</td>
                                <td>4.05</td>
                                <td>5.08</td>
                                <td>5.41</td>
                                <td>7.14</td>
                                <td>7.25</td>
                                <td>10.16</td>
                                <td>9.54</td>
                            </tr>
                            <tr>
                                <td>50</td>
                                <td>2</td>
                                <td>60.3</td>
                                <td>1.65</td>
                                <td>2.4</td>
                                <td>2.77</td>
                                <td>3.93</td>
                                <td>3.91</td>
                                <td>5.44</td>
                                <td>5.54</td>
                                <td>7.48</td>
                                <td>8.74</td>
                                <td>11.1</td>
                                <td>11.07</td>
                                <td>13.44</td>
                            </tr>
                            <tr>
                                <td>65</td>
                                <td>2.5</td>
                                <td>73</td>
                                <td>2.11</td>
                                <td>3.69</td>
                                <td>3.05</td>
                                <td>5.26</td>
                                <td>5.16</td>
                                <td>8.63</td>
                                <td>7.01</td>
                                <td>11.4</td>
                                <td>9.53</td>
                                <td>14.9</td>
                                <td>14.2</td>
                                <td>20.39</td>
                            </tr>
                            <tr>
                                <td>80</td>
                                <td>3</td>
                                <td>88.9</td>
                                <td>2.11</td>
                                <td>4.51</td>
                                <td>3.05</td>
                                <td>6.45</td>
                                <td>5.49</td>
                                <td>11.3</td>
                                <td>7.62</td>
                                <td>15.2</td>
                                <td>11.1</td>
                                <td>21.3</td>
                                <td>15.24</td>
                                <td>27.65</td>
                            </tr>
                            <tr>
                                <td>100</td>
                                <td>4</td>
                                <td>114.3</td>
                                <td>2.11</td>
                                <td>5.84</td>
                                <td>3.05</td>
                                <td>8.36</td>
                                <td>6.02</td>
                                <td>16.07</td>
                                <td>8.56</td>
                                <td>22.3</td>
                                <td>13.49</td>
                                <td>33.54</td>
                                <td>17.12</td>
                                <td>41.03</td>
                            </tr>
                            <tr>
                                <td>125</td>
                                <td>5</td>
                                <td>141.3</td>
                                <td>2.77</td>
                                <td>9.47</td>
                                <td>3.4</td>
                                <td>11.57</td>
                                <td>6.55</td>
                                <td>21.8</td>
                                <td>9.53</td>
                                <td>31.97</td>
                                <td>15.88</td>
                                <td>49.11</td>
                                <td>19.05</td>
                                <td>57.43</td>
                            </tr>
                            <tr>
                                <td>150</td>
                                <td>6</td>
                                <td>168.3</td>
                                <td>2.77</td>
                                <td>11.32</td>
                                <td>3.4</td>
                                <td>13.84</td>
                                <td>7.11</td>
                                <td>28.3</td>
                                <td>10.97</td>
                                <td>42.7</td>
                                <td>18.2</td>
                                <td>67.56</td>
                                <td>21.95</td>
                                <td>79.22</td>
                            </tr>
                            <tr>
                                <td>200</td>
                                <td>8</td>
                                <td>219.1</td>
                                <td>2.77</td>
                                <td>14.79</td>
                                <td>3.76</td>
                                <td>19.96</td>
                                <td>8.18</td>
                                <td>42.6</td>
                                <td>12.7</td>
                                <td>64.6</td>
                                <td>23</td>
                                <td>111.2</td>
                                <td>22.23</td>
                                <td>107.8</td>
                            </tr>
                            <tr>
                                <td>250</td>
                                <td>10</td>
                                <td>273.1</td>
                                <td>3.4</td>
                                <td>22.63</td>
                                <td>4.19</td>
                                <td>27.78</td>
                                <td>9.27</td>
                                <td>60.5</td>
                                <td>12.7</td>
                                <td>96</td>
                                <td>28.6</td>
                                <td>172.4</td>
                                <td>25.4</td>
                                <td>155.15</td>
                            </tr>
                            <tr>
                                <td>300</td>
                                <td>12</td>
                                <td>323.9</td>
                                <td>3.96</td>
                                <td>31.25</td>
                                <td>4.57</td>
                                <td>36</td>
                                <td>9.52</td>
                                <td>73.88</td>
                                <td>12.7</td>
                                <td>132</td>
                                <td>33.32</td>
                                <td>238.76</td>
                                <td>25.4</td>
                                <td>186.97</td>
                            </tr>
                            <tr>
                                <td>350</td>
                                <td>14</td>
                                <td>355.6</td>
                                <td>3.96</td>
                                <td>34.36</td>
                                <td>4.78</td>
                                <td>41.3</td>
                                <td>11.13</td>
                                <td>94.59</td>
                                <td>19.05</td>
                                <td>158.08</td>
                                <td>35.71</td>
                                <td>281.7</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>400</td>
                                <td>16</td>
                                <td>406.4</td>
                                <td>4.19</td>
                                <td>41.56</td>
                                <td>4.78</td>
                                <td>47.29</td>
                                <td>12.7</td>
                                <td>123.3</td>
                                <td>21.41</td>
                                <td>203.33</td>
                                <td>40.46</td>
                                <td>365.11</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>450</td>
                                <td>18</td>
                                <td>457.2</td>
                                <td>4.19</td>
                                <td>46.8</td>
                                <td>4.78</td>
                                <td>53.42</td>
                                <td>14.27</td>
                                <td>155.8</td>
                                <td>23.8</td>
                                <td>254.36</td>
                                <td>45.71</td>
                                <td>466.4</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>500</td>
                                <td>20</td>
                                <td>508</td>
                                <td>4.78</td>
                                <td>59.25</td>
                                <td>5.54</td>
                                <td>68.71</td>
                                <td>15.09</td>
                                <td>183.42</td>
                                <td>26.19</td>
                                <td>311.2</td>
                                <td>49.99</td>
                                <td>564.68</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>600</td>
                                <td>24</td>
                                <td>609.6</td>
                                <td>5.54</td>
                                <td>82.47</td>
                                <td>6.35</td>
                                <td>94.45</td>
                                <td>17.48</td>
                                <td>255.41</td>
                                <td>30.96</td>
                                <td>442.08</td>
                                <td>59.54</td>
                                <td>808.22</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <br/>
            <div className='technical-info-title'>Our extensive inventory of welded and seamless pipes conforms to the ASTM A312 standard specification. View the standard specifications chart.</div>
            <div className="technical_conditions_description">
            <div className="table-responsive">
                    <table className='technical-info-description' >
                    <tbody>
                        <tr>
                            <th colSpan="9"><strong>Specifications</strong></th>
                        </tr>
                        <tr>
                            <td><strong>Grade</strong></td>
                            <td><strong>Pipe</strong></td>
                            <td><strong>Tube</strong></td>
                            <td><strong>Fittings</strong></td>
                            <td><strong>Flanges</strong></td>
                            <td><strong>Plate</strong></td>
                            <td><strong>Bar</strong></td>
                            <td><strong>Forgings</strong></td>
                            <td><strong>UNS No.</strong></td>
                        </tr>
                        <tr>
                            <td>Stainless 304</td>
                            <td>A312</td>
                            <td>A213/249/269</td>
                            <td>A403</td>
                            <td>A182 F304</td>
                            <td>A240</td>
                            <td>A276/479</td>
                            <td>A182</td>
                            <td>S30400</td>
                        </tr>
                        <tr>
                            <td>Stainless 316</td>
                            <td>A312</td>
                            <td>A213/249/269</td>
                            <td>A403</td>
                            <td>A182 F316</td>
                            <td>A240</td>
                            <td>A276/479</td>
                            <td>A182</td>
                            <td>S31600</td>
                        </tr>
                        <tr>
                            <td>Stainless 410</td>
                            <td>A268</td>
                            <td>A213/249/269</td>
                            <td>A815</td>
                            <td>A182 F410</td>
                            <td>A240</td>
                            <td>A276/479</td>
                            <td>A182</td>
                            <td>S41000</td>
                        </tr>
                        <tr>
                            <td>Stainless 317</td>
                            <td>A312</td>
                            <td>A213/249/269</td>
                            <td>A403</td>
                            <td>A182 F317</td>
                            <td>A240</td>
                            <td>A276/479</td>
                            <td>A182</td>
                            <td>S31700</td>
                        </tr>
                        <tr>
                            <td>Stainless 310</td>
                            <td>A312</td>
                            <td>A213/249/269</td>
                            <td>A403</td>
                            <td>A182 F310</td>
                            <td>A240</td>
                            <td>A276/479</td>
                            <td>A182</td>
                            <td>S31000</td>
                        </tr>
                        <tr>
                            <td>Stainless 321</td>
                            <td>A312</td>
                            <td>A213/249/269</td>
                            <td>A403</td>
                            <td>A182 F321</td>
                            <td>A240</td>
                            <td>A276/479</td>
                            <td>A182</td>
                            <td>S32100</td>
                        </tr>
                        <tr>
                            <td>Stainless 347</td>
                            <td>A312</td>
                            <td>A213/249/269</td>
                            <td>A403</td>
                            <td>A182 F347</td>
                            <td>A240</td>
                            <td>A276/479</td>
                            <td>A182</td>
                            <td>S34700</td>
                        </tr>
                        <tr>
                            <td>Stainless 254 SMO</td>
                            <td>A312</td>
                            <td>A213/249/269</td>
                            <td>A403</td>
                            <td>A182 F254</td>
                            <td>A240</td>
                            <td>A/SA182 A/SA479 A/SA276 A/SA193</td>
                            <td>A/SA182</td>
                            <td>S31254</td>
                        </tr>
                        <tr>
                            <td>Alloy 20</td>
                            <td>B/SB729</td>
                            <td>B/SB729</td>
                            <td>B/SB366</td>
                            <td>B/SB462 B16.5</td>
                            <td>B/SB463</td>
                            <td>B/SB462 B/SB473</td>
                            <td>B/SB462</td>
                            <td>N08020</td>
                        </tr>
                        <tr>
                            <td>Duplex 2205</td>
                            <td>A/SA790</td>
                            <td>A/SA789</td>
                            <td>A/SA182 A/SA815</td>
                            <td>A/SA479 A/SA182</td>
                            <td>A/SA240</td>
                            <td>A/SA182 A/SA479 A/SA276 A/SA193</td>
                            <td>A/SA182</td>
                            <td>S31803/S 32205</td>
                        </tr>
                        <tr>
                            <td>Hastelloy C276</td>
                            <td>B/SB619 B/SB622</td>
                            <td>SB-622/SB- 516/SB-626</td>
                            <td>SB-366</td>
                            <td>B/SB574 B/SB564 B16.5</td>
                            <td>B/SB575</td>
                            <td>B/SB574 B/SB564</td>
                            <td>B/SB-564 B/SB462</td>
                            <td>N10276</td>
                        </tr>
                        <tr>
                            <td>Alloy 200/201</td>
                            <td>B/SB161</td>
                            <td>B/SB161 B/SB163</td>
                            <td>B/SB366</td>
                            <td>B/SB160 B/SB564 B16.5</td>
                            <td>B/SB162</td>
                            <td>B/SB160 B/SB564</td>
                            <td>B/SB564</td>
                            <td>N02200/ N02201</td>
                        </tr>
                        <tr>
                            <td>Alloy 400</td>
                            <td>B/SB165</td>
                            <td>B/SB165</td>
                            <td>B/SB366</td>
                            <td>B/SB164 B/SB564 B16.5</td>
                            <td>B/SB127</td>
                            <td>B/SB164 B/SB564 QQ-N-281D</td>
                            <td>SB-564</td>
                            <td>N04400</td>
                        </tr>
                        <tr>
                            <td>Alloy 600</td>
                            <td>B/SB167</td>
                            <td>B/SB167</td>
                            <td>B/SB366</td>
                            <td>B/SB166 B/SB564 B16.5</td>
                            <td>B/SB168</td>
                            <td>B/SB166 B/SB564</td>
                            <td>B/SB564</td>
                            <td>N06600</td>
                        </tr>
                        <tr>
                            <td>Alloy 625</td>
                            <td>B/SB444 B705</td>
                            <td>B/SB444</td>
                            <td>B/SB366</td>
                            <td>B/SB444 B/SB564 B16.5</td>
                            <td>B/SB443</td>
                            <td>B/SB446 B/SB564</td>
                            <td>B/SB564</td>
                            <td>N06625</td>
                        </tr>
                        <tr>
                            <td>Alloy 800H/HP</td>
                            <td>B/SB407</td>
                            <td>SB-407/SB- 829/SB-515/SB-751</td>
                            <td>B/SB366</td>
                            <td>B/SB446 B/SB564 B16.5</td>
                            <td>B/SB409</td>
                            <td>B/SB408 B/SB564</td>
                            <td>B/SB564</td>
                            <td>NO8810/ N08811</td>
                        </tr>
                        <tr>
                            <td>Alloy 825</td>
                            <td>B/SB423</td>
                            <td>B/SB423</td>
                            <td>B/SB366</td>
                            <td>B/SB425 B/SB564 B16.5</td>
                            <td>B/SB424</td>
                            <td>B/SB425 B/SB564</td>
                            <td>B/SB564</td>
                            <td>N08825</td>
                        </tr>
                        <tr>
                            <td>Carbon Steel</td>
                            <td>A53</td>
                            <td></td>
                            <td>A234 WPB</td>
                            <td>A105</td>
                            <td>A36</td>
                            <td></td>
                            <td>A105</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Carbon Steel</td>
                            <td>A106B</td>
                            <td></td>
                            <td>A234 WPB</td>
                            <td>A105</td>
                            <td>A36</td>
                            <td></td>
                            <td>A105</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Carbon Steel</td>
                            <td>A106C</td>
                            <td></td>
                            <td>A234 WPB</td>
                            <td>A105</td>
                            <td>A36</td>
                            <td></td>
                            <td>A105</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Low Temp C.S. Gr. 1</td>
                            <td>A333 GR. 1</td>
                            <td>A334 GR. 1</td>
                            <td>A420 WPL1/6</td>
                            <td>A350 LF2</td>
                            <td></td>
                            <td>A516</td>
                            <td>A350 LF2</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Low Temp C.S. Gr. 3</td>
                            <td>A333 GR. 3</td>
                            <td>A334 GR. 3</td>
                            <td>A420 WPL3</td>
                            <td>A350 LF3</td>
                            <td></td>
                            <td>A516</td>
                            <td>A350 LF3</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Low Temp C.S. Gr. 6</td>
                            <td>A333 GR. 6</td>
                            <td>A334 GR. 6</td>
                            <td>A420 WPL6</td>
                            <td>A350 LF2</td>
                            <td></td>
                            <td>A516</td>
                            <td>A350 LF2</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Cr-Mo Grade 5</td>
                            <td>A335 P5</td>
                            <td>A213 T5</td>
                            <td>A234 WP5</td>
                            <td>A182 F5</td>
                            <td></td>
                            <td>A387 GRADE 5</td>
                            <td>A182 F5</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Cr-Mo Grade 9</td>
                            <td>A335 P9</td>
                            <td>A213 T9</td>
                            <td>A234 WP9</td>
                            <td>A182 F9</td>
                            <td></td>
                            <td>A387 GRADE 9</td>
                            <td>A182 F9</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Cr-Mo Grade 11</td>
                            <td>A335 P11</td>
                            <td>A213 T11</td>
                            <td>A234 WP11</td>
                            <td>A182 F11</td>
                            <td></td>
                            <td>A387 GRADE 11</td>
                            <td>A182 F11</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Cr-Mo Grade 22</td>
                            <td>A335 P22</td>
                            <td>A213 T22</td>
                            <td>A234 WP22</td>
                            <td>A182 F22</td>
                            <td></td>
                            <td>A387 GRADE 22</td>
                            <td>A182 F22</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Cr-Mo Grade 91</td>
                            <td>A335 P91</td>
                            <td>A213 T91</td>
                            <td>A234 WP91</td>
                            <td>A182 F91</td>
                            <td></td>
                            <td>A387 GRADE 91</td>
                            <td>A182 F91</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
         </div>
         <br/>
         <div className='technical-info-title'>Stainless Steel Fastener Grades & Chemical Composition</div>
         <div className="technical_conditions_description">
            <div className="table-responsive">
            <table className='technical-info-description' >
                    <tbody>
                        <tr>
                            <th colSpan="18">Stainless Steel Fasteners Chemical Composition</th>
                        </tr>
                        <tr>
                            <td>GRADE</td>
                            <td>UNS</td>
                            <td>DIN</td>
                            <td>Steel Name</td>
                            <td>Carbon (C)</td>
                            <td>Chromium (Cr)</td>
                            <td>Manganese (Mn)</td>
                            <td>Silicon (Si)</td>
                            <td>Phosphorous (P)</td>
                            <td>Sulphur (S)</td>
                            <td>Nickel (Ni)</td>
                            <td>Molybdenum (Mo)</td>
                            <td>Nitrogen (N)</td>
                            <td>Iron (Fe)</td>
                            <td>Copper (Cu)</td>
                            <td>Titanium (Ti)</td>
                            <td>Aluminum (Al)</td>
                            <td>Others</td>
                        </tr>
                        <tr>
                            <td>304</td>
                            <td>S30400</td>
                            <td>1.4301</td>
                            <td>X2CrNi18-20</td>
                            <td>0.07</td>
                            <td>17.50 – 19.50</td>
                            <td>2</td>
                            <td>1</td>
                            <td>0.045</td>
                            <td>0.015</td>
                            <td>8 – 10.5</td>
                            <td></td>
                            <td>0.1</td>
                            <td>Bal</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>304L</td>
                            <td>S30403</td>
                            <td>1.4307</td>
                            <td>X2CrNi18-9</td>
                            <td>0.03</td>
                            <td>17.50 – 19.50</td>
                            <td>2</td>
                            <td>1</td>
                            <td>0.045</td>
                            <td>0.015</td>
                            <td>8 – 10.5</td>
                            <td></td>
                            <td>0.1</td>
                            <td>Bal</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>309</td>
                            <td></td>
                            <td>1.4828</td>
                            <td></td>
                            <td>0.2</td>
                            <td>19 – 21</td>
                            <td>2</td>
                            <td>1.50 – 2.00</td>
                            <td>0.045</td>
                            <td>0.015</td>
                            <td>11.00 – 13.00</td>
                            <td></td>
                            <td>0.11</td>
                            <td>Bal</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>310</td>
                            <td>S31008</td>
                            <td>1.4845</td>
                            <td>X8CrNi25-21</td>
                            <td>0.25</td>
                            <td>24 – 26</td>
                            <td>2</td>
                            <td>1.5</td>
                            <td>0.045</td>
                            <td>0.03</td>
                            <td>19 – 22</td>
                            <td></td>
                            <td></td>
                            <td>Bal</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>310S</td>
                            <td></td>
                            <td>1.4845</td>
                            <td></td>
                            <td>0.08</td>
                            <td>24 – 26</td>
                            <td>2</td>
                            <td>1.5</td>
                            <td>0.045</td>
                            <td>0.03</td>
                            <td>19 – 22</td>
                            <td></td>
                            <td></td>
                            <td>Bal</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>316</td>
                            <td>S31500</td>
                            <td>1.4401</td>
                            <td>X5CrNiMo17-12-2</td>
                            <td>0.07</td>
                            <td>16.50 – 18.50</td>
                            <td>2</td>
                            <td>1</td>
                            <td>0.05</td>
                            <td>0.02</td>
                            <td>10.0 – 13.0</td>
                            <td>2.00 – 2.50</td>
                            <td></td>
                            <td>Bal</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>316L</td>
                            <td>S31603</td>
                            <td>1.4404</td>
                            <td>X5CrNiMo17-12-2</td>
                            <td>0.03</td>
                            <td>16.50 – 18.50</td>
                            <td>2</td>
                            <td>1</td>
                            <td>0.05</td>
                            <td>0.02</td>
                            <td>10.0 – 13.0</td>
                            <td>2.00 – 2.50</td>
                            <td></td>
                            <td>Bal</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>316Ti</td>
                            <td>S31635</td>
                            <td>1.4571</td>
                            <td>X6CrNiTi18-10</td>
                            <td>0.08</td>
                            <td>16.50 – 18.50</td>
                            <td>2</td>
                            <td>1</td>
                            <td>0.05</td>
                            <td>0.03</td>
                            <td>10.50 – 14.0</td>
                            <td>2.00 – 2.50</td>
                            <td></td>
                            <td>Bal</td>
                            <td></td>
                            <td>0.4 – 0.7</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>317</td>
                            <td>S31700</td>
                            <td></td>
                            <td></td>
                            <td>0.08</td>
                            <td>17 – 19</td>
                            <td>2</td>
                            <td>1</td>
                            <td>0.045</td>
                            <td>0.03</td>
                            <td>13</td>
                            <td>3.5</td>
                            <td></td>
                            <td>Bal</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>321</td>
                            <td>S32100</td>
                            <td>1.4541</td>
                            <td>X6CrNiTi18-10</td>
                            <td>0.08</td>
                            <td>17 – 19</td>
                            <td>2</td>
                            <td>0.75</td>
                            <td>0.045</td>
                            <td>0.03</td>
                            <td>9.0 – 12.0</td>
                            <td></td>
                            <td>0.1</td>
                            <td>Bal</td>
                            <td></td>
                            <td>0.5 – 0.7</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>321H</td>
                            <td>S32109</td>
                            <td>1.4878</td>
                            <td>X10CrNiTi18-10</td>
                            <td>0.4 – 0.10</td>
                            <td>17 – 19</td>
                            <td>2</td>
                            <td>0.75</td>
                            <td>0.045</td>
                            <td>0.03</td>
                            <td>9.0 – 12.0</td>
                            <td></td>
                            <td>0.1</td>
                            <td>Bal</td>
                            <td></td>
                            <td>0.4 – 0.7</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>347</td>
                            <td>S34700</td>
                            <td>1.4550</td>
                            <td>X6CrNiNb18-10</td>
                            <td>0.08</td>
                            <td>17 – 19</td>
                            <td>2</td>
                            <td>0.75</td>
                            <td>0.045</td>
                            <td>0.03</td>
                            <td>9.0 – 13.0</td>
                            <td></td>
                            <td>0.1</td>
                            <td>Bal</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>NB (1.0)</td>
                        </tr>
                        <tr>
                            <td>409</td>
                            <td>S40900</td>
                            <td>1.4512</td>
                            <td>X6CrTi12</td>
                            <td>0.08</td>
                            <td>10.50 – 11.75</td>
                            <td>1</td>
                            <td>1</td>
                            <td>0.045</td>
                            <td>0.045</td>
                            <td>0.5</td>
                            <td></td>
                            <td></td>
                            <td>Bal</td>
                            <td></td>
                            <td>0.75</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>410</td>
                            <td>S41000</td>
                            <td>1.4006</td>
                            <td>X12Cr13</td>
                            <td>0.15</td>
                            <td>11.5 – 13.5</td>
                            <td>1</td>
                            <td>1</td>
                            <td>0.04</td>
                            <td>0.03</td>
                            <td>0.75</td>
                            <td></td>
                            <td></td>
                            <td>Bal</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>410S</td>
                            <td>S41008</td>
                            <td>1.4034</td>
                            <td></td>
                            <td>0.08</td>
                            <td>12.5</td>
                            <td>1</td>
                            <td>1</td>
                            <td>0.04</td>
                            <td>0.03</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>Bal</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>430</td>
                            <td>S43000</td>
                            <td>1.4016</td>
                            <td>X6Cr17</td>
                            <td>0.08</td>
                            <td>16.0 – 18.0</td>
                            <td>1</td>
                            <td>1</td>
                            <td>0.04</td>
                            <td>0.02</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>Bal</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>441</td>
                            <td>S44100</td>
                            <td>1.4509</td>
                            <td></td>
                            <td>0.03</td>
                            <td>17.5 – 18.5</td>
                            <td>1</td>
                            <td>1</td>
                            <td>0.04</td>
                            <td>0.015</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>Bal</td>
                            <td></td>
                            <td>0.1 – 0.6</td>
                            <td></td>
                            <td>Nb (1.0)</td>
                        </tr>
                        <tr>
                            <td>904L</td>
                            <td>S08904</td>
                            <td>1.4539</td>
                            <td>X1NiCrMoCu25-20-5</td>
                            <td>0.02</td>
                            <td>19.0 – 23.0</td>
                            <td>2</td>
                            <td>1</td>
                            <td>0.045</td>
                            <td>0.035</td>
                            <td>23.0 – 28.0</td>
                            <td>4.0 – 5.0</td>
                            <td></td>
                            <td>Bal</td>
                            <td>1.0 – 2.0</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>TP 2205</td>
                            <td>S31803</td>
                            <td>1.4462</td>
                            <td>X2CrNiMoN22-5-3</td>
                            <td>0.03</td>
                            <td>21.0 – 23.0</td>
                            <td>2</td>
                            <td>1</td>
                            <td>0.03</td>
                            <td>0.02</td>
                            <td>4.5 – 6.5</td>
                            <td>2.5 – 3.5</td>
                            <td>0.08 – 0.20</td>
                            <td>Bal</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>S32205</td>
                            <td>1.4410</td>
                            <td>X2CrNiMoN25-7-4</td>
                            <td>0.03</td>
                            <td>22.0 – 23.0</td>
                            <td>2</td>
                            <td>1</td>
                            <td>0.03</td>
                            <td>0.02</td>
                            <td>4.5 – 6.5</td>
                            <td>3.0 – 3.5</td>
                            <td>0.14 – 0.20</td>
                            <td>Bal</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>TP 2507</td>
                            <td>S32750</td>
                            <td>1.4501</td>
                            <td>X2CrNiMoCuWN25-7-4</td>
                            <td>0.03</td>
                            <td>24.0 – 26.0</td>
                            <td>1.2</td>
                            <td>0.8</td>
                            <td>0.035</td>
                            <td>0.02</td>
                            <td>6.0 – 8.0</td>
                            <td>3.0 – 5.0</td>
                            <td>0.24 – 0.32</td>
                            <td>Bal</td>
                            <td>0.5</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>S32760</td>
                            <td></td>
                            <td></td>
                            <td>0.03</td>
                            <td>24.0 – 26.0</td>
                            <td>1</td>
                            <td>1</td>
                            <td>0.035</td>
                            <td>0.015</td>
                            <td>6.0 – 8.0</td>
                            <td>3.0 – 4.0</td>
                            <td>0.3</td>
                            <td>Bal</td>
                            <td>1</td>
                            <td></td>
                            <td></td>
                            <td>W (1.0)</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </div>
        </div>
    </div>)
}
export default TechnicalInformation;