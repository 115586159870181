import AboutFlometals from "../Home/AboutFlometals";
import PageHeader from "../../PageHeader";
import './about.css';
import { Row, Col } from "antd";
import stainlesssteelpipe from '../../img/stainlesssteelpipe.jpg';
import carbonpipe from '../../img/carbonpipe.jpg';
import colddrawingsection from '../../img/colddrawingsection.jpg';
import coldpilgersection from '../../img/coldpilgersection.jpg';
import annealing from '../../img/annealing.jpg';
import qualitypolicybg from '../../img/qualitypolicybg.jpg';
import ReactGA from 'react-ga';
import { useEffect } from 'react';


const TestingFacilitiesList = ['Chemical Analysis', 'Physical Testing', 'Hardness Testing', 'Hydrostatic Testing'
    , 'Pneumatic Testing', 'Stress Corrossion Testing', 'Dimensional Inspection', 'Destructive Testing- Flattening', 'Drift Test',
    'Bend Test', 'NDT - Eddy Current Testing']

const About = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    }, [])
    return (<>
        <PageHeader title="About Us" background={qualitypolicybg}/>
        <AboutFlometals readMore={false} />
        <div className="about-page-testing-facilities-list">
            <Row>
                <Col xs={24} md={0}><hr /></Col>
                <Col xs={0} md={24} lg={0} xl={0} xxl={0}><br /><br /></Col>
                <Col xs={0} md={0} lg={24} xl={24} xxl={24}><br /><br /><br /><br /></Col>
            </Row>
            <div className="about-page-testing-facilities-list-title">
                Testing Facilities
            </div>
            <ul className="square">
                <Row>
                    {TestingFacilitiesList.map(item =>
                        <Col xs={24} md={12} lg={7} xl={6}>
                            <li>{item}</li>
                        </Col>
                    )}
                </Row>
            </ul>
            <div className="about-page-testing-facilities-list-title">Raw Materials  </div>
            <div className="manufacturing-process-description">Pipes and Tubes are manufactured using raw materials from authorized sources. Technical criteria established by the quality assurance department and pertinent technical specifications determine the quality requirements for raw materials.</div>
            <br />
            <Row gutter={[46, 16]}>
                <Col xs={24} lg={18}>
                    <div className="about-page-testing-facilities-list-title">Stainless Steel Pipe</div>
                    <div className="manufacturing-process-description">
                        According to the needs of the customer, Flo Metals Industries has a facility for TIG Stainless Steel Pipe with bead rolling.
                        <br />
                        As a result of the critical and corrosive environments that Stainless Steel Pipe are used in, the quality of the Pipe is maintained with a precise tolerance in accordance with the necessary national and international standards.
                    </div>
                </Col>
                <Col xs={24} lg={6}>
                    <img src={stainlesssteelpipe} alt="" width='150px' height='150px' />
                </Col>
            </Row>
            <br />
            <Row gutter={[46, 16]}>
                <Col xs={0} lg={4}>
                    <img src={carbonpipe} alt="" width='150px' height='150px' />
                </Col>
                <Col xs={24} lg={20}>
                    <div className="about-page-testing-facilities-list-title">Carbon Steel Pipe</div>
                    <div className="manufacturing-process-description">
                        The Carbon Steel Pipe are subsequently sized and straightened using a hydraulic press with a 150 M.T. capacity to match the specifications' dimensional criteria. Tests are carried out under specifications and further quality checks using the hydrostatic or X-ray technique.
                        <br />
                        On a 1000 M.T. hydraulic press, Carbon pipes with lengths of up to 6.0 to 7.0 meters are manufactured. Carbon pipes are produced in this section using TIG welding and filler materials under ASTM A 358.                    </div>
                </Col>
                <Col xs={24} lg={0}>
                    <img src={carbonpipe} alt="" width='150px' height='150px' />
                </Col>
            </Row>
            <br />
            <Row gutter={[46, 16]}>
                <Col xs={24} lg={18}>
                    <div className="about-page-testing-facilities-list-title">Cold Drawing Section</div>
                    <div className="manufacturing-process-description">
                        The Flo Metals has installed draw benches for Cold Drawing of Tubes & Pipes for Seamless and Welded products.
                        <br />
                        When drawing on top-quality Tubes, a thin layer is created on both the exterior and interior of the Tubes by coating each one individually with chemicals and soap. The tubes are then pulled to decrease their diameter and thickness using a tungsten carbide die and plugs. They are solution annealed once more. Before packaging and dispatch, the item was straightened, pickled, hydro-tested, passivated, labeled correctly, and subjected to the suggested tests.                    </div>
                </Col>
                <Col xs={24} lg={6}>
                    <img src={colddrawingsection} alt="" width='150px' height='150px' />
                </Col>
            </Row>
            <br />
            <Row gutter={[46, 16]}>
                <Col xs={0} lg={4}>
                    <img src={coldpilgersection} alt="" width='150px' height='150px' />
                </Col>
                <Col xs={24} lg={20}>
                    <div className="about-page-testing-facilities-list-title">Cold Pilger Section</div>
                    <div className="manufacturing-process-description">
                        The longitudinal cold-rolling method known as cold pilgering decreases the diameter and wall thickness of metal tubes in a single operation. Depending on the material, the cold Pilger method can reduce the cross-section by more than 90% in a single working cycle.
                        <br />
                        The cold pilgering process relies on four main actions: The tube moves forward and it rotates while the ring dies move back and forth and rotate.                         </div>
                </Col>
                <Col xs={24} lg={0}>
                    <img src={coldpilgersection} alt="" width='150px' height='150px' />
                </Col>
            </Row>
            <br />
            <Row gutter={[46, 16]}>
                <Col xs={24} lg={18}>
                    <div className="about-page-testing-facilities-list-title">Annealing</div>
                    <div className="manufacturing-process-description">
                        A fully automated roller hearth furnace with temperature recorders and controls is used for annealing or heat treating the Tubes. It entails quickly quenching the Tubes after heating them to a specific temperature.
                        <br />
                        <ul>
                            <li>Reduce hardness and increase plasticity,</li>
                            <li>Refine grain and eliminate tissue defects</li>
                            <li>Eliminate internal stress</li>
                            <li>Prepare for quenching the organization</li>
                        </ul>
                    </div>
                </Col>
                <Col xs={24} lg={6}>
                    <img src={annealing} alt="" width='200px' height='200px' />
                </Col>
            </Row>
            <br />
            <div className="about-page-testing-facilities-list-title">Manufacturing Facilities in House </div>
            <div className="manufacturing-process-description">
                <ul>
                    <li>Tube Mill Machines</li>
                    <li>Draw Bench Machines</li>
                    <li>Pillager Machines</li>
                    <li>Stxel Rolling Mill Machinery</li>
                    <li>Stainless Steel Pipe-Tube Polishing Machine</li>
                    <li>Cold Forming Machine (1500 MT)</li>
                    <li>Straightening Machines</li>
                    <li>H-Frame 200 Ton Presses</li>
                    <li>Hydraulic Press (300 MT)</li>
                    <li>600 MT Hydraulic Press (SR Hydraulics)</li>
                </ul>
            </div>
            <br />
        </div>
    </>)
}
export default About;