import { Carousel, Col, Row } from 'antd';
import { useRef } from 'react';
import "./Home.css";
import ProductCard from './ProductCard';
import areospaceindustry from '../../img/FloMetalAndTubes/areospaceindustry.png';
import chemicalindustry from '../../img/FloMetalAndTubes/chemicalindustry.png';
import oilgasindsutry from '../../img/FloMetalAndTubes/oilgasindsutry.png';
import powerplants from '../../img/FloMetalAndTubes/powerplants.png';
import sugarmills from '../../img/FloMetalAndTubes/sugarmills.png';
import shipbuilding from '../../img/FloMetalAndTubes/shipbuilding.png';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const ProductList_LG = [
    [
        {
            productImg: areospaceindustry,
            title: 'Aerospace Industry',
        },
        {
            productImg: chemicalindustry,
            title: 'Chemical Industry',
        },
        {
            productImg: oilgasindsutry,
            title: 'Oil Gas Indsutry',
        }],
    [
        {
            productImg: shipbuilding,
            title: 'Ship Building',
        },
        {
            productImg: powerplants,
            title: 'Power Plants',
        },
        {
            productImg: sugarmills,
            title: 'Food Indsutry',
        }]
]
const ProductList_MD = [
    [
        {
            productImg: areospaceindustry,
            title: 'Aerospace Industry',
        },
        {
            productImg: chemicalindustry,
            title: 'Chemical Industry',
        }
    ],
    [
        {
            productImg: oilgasindsutry,
            title: 'Oil Gas Indsutry',
        },
        {
            productImg: shipbuilding,
            title: 'Ship Building',
        },
    ],
    [
        {
            productImg: powerplants,
            title: 'Power Plants',
        },
        {
            productImg: sugarmills,
            title: 'Food Indsutry',
        }
    ]
]

const ProductList_XS = [
    {
        productImg: areospaceindustry,
        title: 'Aerospace Industry',
    },
    {
        productImg: chemicalindustry,
        title: 'Chemical Industry',
    },
    {
        productImg: oilgasindsutry,
        title: 'Oil Gas Indsutry',
    },
    {
        productImg: shipbuilding,
        title: 'Ship Building',
    },
    {
        productImg: powerplants,
        title: 'Power Plants',
    },
    {
        productImg: sugarmills,
        title: 'Food Indsutry',
    }
]

const FloMetalAndTubes = () => {
    const sliderLG = useRef(null);
    const sliderMD = useRef(null);
    const sliderXS = useRef(null);
    return (<>
        <Row>
            <Col xs={0} md={0} lg={24}>
                <Carousel autoplay ref={sliderLG} dots={false}>
                    {ProductList_LG.map(item => <div>
                        <Row gutter={[16, 0]} className='col3' justify='center' align='middle'>
                            <Col span={1}><LeftOutlined className='left-right-icon' onClick={() => sliderLG.current.prev()} /></Col>
                            {item.map(entity => <Col span={7}>
                                <ProductCard productImg={entity.productImg} title={entity.title} />
                            </Col>)}
                            <Col span={1}><RightOutlined className='left-right-icon' onClick={() => sliderLG.current.next()} /></Col>
                        </Row>
                    </div>)}
                </Carousel>
            </Col>
            <Col xs={0} md={24} lg={0}>
                <Carousel autoplay ref={sliderMD} dots={false}>{ProductList_MD.map(item => <div>
                    <Row gutter={[16, 0]} className='col3' justify='center' align='middle'>
                        <Col span={2}><LeftOutlined className='left-right-icon' onClick={() => sliderMD.current.prev()} /></Col>
                        {item.map(entity => <Col span={10}>
                            <ProductCard productImg={entity.productImg} title={entity.title} />
                        </Col>)}
                        <Col span={2}><RightOutlined className='left-right-icon' onClick={() => sliderMD.current.next()} /></Col>
                    </Row>
                </div>)}
                </Carousel>
            </Col>
            <Col xs={24} md={0}>
                <Carousel autoplay ref={sliderXS} dots={false}>{ProductList_XS.map(item => <div>
                    <Row className='col1' justify='space-between' align='middle'>
                        <Col span={1}><LeftOutlined className='left-right-icon' onClick={() => sliderXS.current.prev()} /></Col>
                        <Col span={18}>
                            <ProductCard productImg={item.productImg} title={item.title} />
                        </Col>
                        <Col span={1}><RightOutlined className='left-right-icon' onClick={() => sliderXS.current.next()} /></Col>
                    </Row>
                </div>)}
                </Carousel>
            </Col>
        </Row>
    </>)
}
export default FloMetalAndTubes;