import './Products.css';
import PageHeader from '../../PageHeader';
import { Row, Col } from 'antd';
import oletsmanufacturer from '../../img/Product/oletsmanufacturer.jpg';
import nippolets from '../../img/Product/nippolets.jpg';
import elbowletsfittingsmanufacturer from '../../img/Product/elbowletsfittingsmanufacturer.jpg';
import lateroletsmanufacturer from '../../img/Product/lateroletsmanufacturer.jpg';
import sockoletsmanufacturer from '../../img/Product/sockoletsmanufacturer.jpg';
import weldolets from '../../img/Product/weldolets.jpg';
import threolets from '../../img/Product/threolets.jpg';
import oletsBg from '../../img/Product/oletsBg.jpg';
import '../Pages.css';
import ReactGA from 'react-ga';
import { useEffect } from 'react';
const OletsManfacturer = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    }, [])
    return (<div>
        <PageHeader title='Olets Manfacturer in India' background={oletsBg}/>
        <div className='product-info'>
            <Row gutter={[32, 16]}>
                <Col xs={24} md={12}>
                    <img src={oletsmanufacturer} width="100%" height="100%" className='product-img' alt='' />
                </Col>
                <Col xs={24} md={12}>
                    <div className='product-info-title'>Olets Manufacturer, Supplier, and Stockists in India</div>
                    <div className='product-info-description'>Flo Metal &amp; Tubes is a well-known <b>Olets Manufacturer, Supplier, and Exporter in India</b>. We have a significant manufacturer and supplier of Stainless Steel <u> Nippolet, Elbowlet, Latrolet, Sockolet &amp; Weldolet, Threadolet</u>. Olets are utilised in many sectors, including oil and gas, petroleum, petrochemical, chemical, machine construction, cars, and so on. To suit the demands of our clients, these Olets are available in a range of sizes, specifications, grade materials, and forms.</div>
                </Col>
                <Col xs={0} md={24}><br /></Col>
            </Row>
            <div className='product-info-title'>Olets Manufacturer, Carbon Steel Olets Supplier, Duplex Steel Olets Stockists, Nickel Alloy Olets Manufacturer in India.</div>
            <div className='product-info-description'>Flo Metal &amp; Tubes is one of India's leading manufacturer of high-quality <u>Olets</u> that are used in a variety of industries. <b>Olets</b> is created by pressing a hard circular steel 'material' over a form and heating it until the steel is wrought into a Olets Fittings. Our <em>Elbowlet</em> provide consistent strength and structure throughout the Olets body. As a result, these Olets can withstand greater temperatures, higher pressures, corrosive environments, and increased mechanical stress.</div>
            <div className='product-info-description'>We have a competent team to ensure the quality of <b>Olets</b> and other goods throughout the manufacturing and supplying process, which includes selecting high-quality raw materials, turning them into completed products, identifying, packing, storage, and transportation. We are also the largest Supplier of <a href="/products/socketweld-fittings">Socketweld Fittings</a>.</div>
            <br />
            <div className='product-info-title'>Olets Specifications</div>
            <div className="table-responsive">
                <table border="1" className='product-info-description'>
                    <tbody>
                        <tr>
                            <th colSpan="2"><strong>Olets</strong></th>
                        </tr>
                        <tr>
                            <td><strong>Elements</strong></td>
                            <td> Nippolet, Elbowlet, Latrolet, Sockolet &amp; Weldolet, Threadolet</td>
                        </tr>
                        <tr>
                            <td><strong>Stainless Steel </strong></td>
                            <td> ASTM A-182, F304L, 316L, 310, 904l</td>
                        </tr>

                        <tr>
                            <td><strong>Carbon Steel </strong></td>
                            <td> ASTM A 105</td>
                        </tr>
                        <tr>
                            <td><strong>Size </strong></td>
                            <td>B/W Standard &amp; Extra Long 1/8” To 24”, Sch. 160 1/2” To 6” 3000# Threaded And S/W 1/8” To 4”, 6000# Threaded And S/W 1/2” To 2”</td>
                        </tr>
                        <tr>
                            <td><strong>Rating </strong></td>
                            <td> 3000# (for Small Bore Branches)</td>
                        </tr>
                        <tr>
                            <td><strong>Ends </strong></td>
                            <td>: Nippolet : Plain End, Sockolet : S/W End, Weldolet &amp; Elbowlet : B/W, Letrolet : S/W, B/W (as Per Branch Size)
                            </td>
                        </tr>
                        <tr>
                            <td><strong>Grade </strong></td>
                            <td> Duplex, Super Duplex, Inconel, Monel, Hastelloy C, Nickel Alloy, Alloy 20 Etc.
                            </td>
                        </tr>
                        <tr>
                            <td><strong>Dim Standard </strong></td>
                            <td> MSS-SP-97
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br />
            <div className='product-info-title'>Types of Olets</div>
            <Row align='middle' gutter={[32, 16]}>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={nippolets} width="100%" height="100%" alt='' />
                    <div className='pipes-type-name product-info-title'>Nippolet</div>
                </Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={elbowletsfittingsmanufacturer} width="100%" height="100%" alt='' />
                    <div className='pipes-type-name product-info-title'>Elbowlet</div>
                </Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={lateroletsmanufacturer} width="100%" height="100%" alt='' />
                    <div className='pipes-type-name product-info-title'>Latrolet</div>
                </Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={sockoletsmanufacturer} width="100%" height="100%" alt='' />
                    <div className='pipes-type-name product-info-title'>Sockolet </div>
                </Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={weldolets} width="100%" height="100%" alt='' />
                    <div className='pipes-type-name product-info-title'>Weldolet</div>
                </Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={threolets} width="100%" height="100%" alt='' />
                    <div className='pipes-type-name product-info-title'>Threadolet</div>
                </Col>
                <Col xs={3} sm={0}></Col>
            </Row>
            {/* <br />
            <div className='product-info-title'>Olets Supplier - Additional Information</div>
            <div className='product-info-description'>
                <ol>
                    <li>Olets Payment Modes: LC (Letter of Credit), TT (Telegraphic Transfer or Wire Transfer), Cheque, and others.</li>
                    <li>Olets Packaging: Olets are packaged with caution and safety so they reach our client’s as good as he would expect. Packaging Charges Extra.</li>
                    <li>Olets Port of Dispatch: Mumbai ports in the Maharashtra state of India.</li>
                    <li>Olets Tax: 18% GST.</li>
                </ol>
            </div>
            <div style={{ padding: '2vw 0', textAlign: 'center' }}><span className='markup-text'>Flo Pipes & Fittings is a leading Carbon Steel Pipes and Tubes Manufacturer in India.</span></div>
            <br />
            <div className='product-info-title'>Applications & Uses of Olets</div>
            <div className='product-info-description'>
                <ul>
                    <li>Olets used in Sugar Industry.</li>
                    <li>Olets used in Power Industry.</li>
                    <li>Olets used in ShipBuilding Industry.</li>
                    <li>Olets used in Mechanical and Plant engineering.</li>
                    <li>Olets used in Food, Beverage, and Dairy.</li>
                    <li>Olets used in Oil and Gas Plant.</li>
                    <li>Olets used in Process Instrumentation.</li>
                </ul>
            </div> */}
        </div>
    </div>)
}
export default OletsManfacturer;