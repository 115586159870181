import './Footer.css'
import { Row, Col, Button } from 'antd';
import { FacebookOutlined, TwitterOutlined, GoogleOutlined, InstagramOutlined, WhatsAppOutlined } from '@ant-design/icons'
import logo from '../img/logo.png';

const Footer = () => {
    return (<div className='footer'>
        <Row gutter={[46, 0]}>
            <Col sm={24} md={12}>
                <a href="/"><img src={logo} width="100%" alt=''></img></a>
                <div className='footer_text'>We take pride in introducing ourselves as one of the leading Manufacturer, Stockist, and Supplier of a comprehensive range of premium quality Stainless Steel and Ferrous and Non-Ferrous metal.</div>
                <Row align={'middle'} gutter={[32, 8]} className='footer_social_media'>
                    <Col span={24}></Col>
                    <Col xs={0} md={4} lg={3} xl={2} xxl={2}> <Button shape="circle" href='#' icon={<FacebookOutlined className="social_media_icon" />} size='large' /></Col>
                    <Col xs={0} md={4} lg={3} xl={2} xxl={2}> <Button shape="circle" href='#' icon={<TwitterOutlined className="social_media_icon" />} size='large' /></Col>
                    <Col xs={0} md={4} lg={3} xl={2} xxl={2}> <Button shape="circle" href='#' icon={<GoogleOutlined className="social_media_icon" />} size='large' /></Col>
                    <Col xs={0} md={4} lg={3} xl={2} xxl={2}> <Button shape="circle" href='#' icon={<InstagramOutlined className="social_media_icon" />} size='large' /></Col>
                    <Col xs={0} md={4} lg={3} xl={2} xxl={2}> <Button shape="circle" href="https://api.whatsapp.com/send/?phone=919820810810&text&type=phone_number&app_absent=0" icon={<WhatsAppOutlined className="social_media_icon" />} size='large' /></Col>
                </Row>
            </Col>
            <Col sm={24} md={12}>
                <h2 className='footer_text' style={{marginBottom:'5px',color:'#f6f3f3'}}>CONTACT</h2>
                <div className='footer_text'><b>Registered Office Address:</b>  Flo Pipes And Fittings Co 5 / 502 A Wing, Suryadarshan Building, S.S.M.Marg, Kumbharwada 2nd Lane, Mumbai - 400004.</div>
                <div className='footer_text'><b>Office Address:</b> 15/C, 4th Floor ,Pandurang Bhuvan Building ,S.S.M.Marg, Kumbharwada 2nd Lane ,Mumbai - 400004</div> 
                <div className='footer_text'><b>Mob: </b><a href="tel:919820810810" style={{ color: '#ddd' }}>+91 9820810810</a>  </div>
                {/* <div className='footer_text'><b>Tel: </b>  <a href="tel:919619023475" style={{ color: '#ddd' }}>+91 9820810810</a>   </div> */}
                <div className='footer_text'><b>Mail:  </b> <a href="mailto:info@flometal.com" style={{ color: '#ddd' }}>info@flometal.com </a>, <a href="mailto:sales@flometal.com" style={{ color: '#ddd' }}>sales@flometal.com</a></div>
            </Col>
        </Row>
        <Row align={'middle'} gutter={[46, 16]}>
            <Col span={24}></Col>
            <Col xs={3} md={0}> <Button shape="circle" href='#' icon={<FacebookOutlined className="social_media_icon" />} size='large' /></Col>
            <Col xs={3} md={0}> <Button shape="circle" href='#' icon={<TwitterOutlined className="social_media_icon" />} size='large' /></Col>
            <Col xs={3} md={0}> <Button shape="circle" href='#' icon={<GoogleOutlined className="social_media_icon" />} size='large' /></Col>
            <Col xs={3} md={0}> <Button shape="circle" href='#' icon={<InstagramOutlined className="social_media_icon" />} size='large' /></Col>
            <Col xs={3} md={0}> <Button shape="circle" href='https://api.whatsapp.com/send/?phone=919820810810&text&type=phone_number&app_absent=0' icon={<WhatsAppOutlined className="social_media_icon" />} size='large' /></Col>
        </Row>
        <Row align={'middle'} justify='end' gutter={[16, 0]}>
            <Col span={24}><br /><hr /></Col>
            <Col xs={12} md={4} lg={3} xl={3} xxl={2} className='footer_text'> <Button type='link' href='/privacy-policy' style={{ color: 'white' }}>Privacy Policy</Button></Col>
            <Col xs={12} md={5} lg={4} xl={3} xxl={3} className='footer_text'> <Button type='link' href='/terms-condition' style={{ color: 'white' }}>Terms Condition</Button></Col>
        </Row>
    </div>)
}
export default Footer;