import './Products.css';
import PageHeader from '../../PageHeader';
import { Row, Col } from 'antd';
import flatsanglechannelmanufacturer from '../../img/Product/flatsanglechannelmanufacturer.jpg';
import flatbarmanufacturer from '../../img/Product/flatbarmanufacturer.jpg';
import anglemanufcaturer from '../../img/Product/anglemanufcaturer.jpg';
import channelmanufacturer from '../../img/Product/channelmanufacturer.jpg';
import beammanufacturer from '../../img/Product/beammanufacturer.jpg';
import flatsanglechannelbg from '../../img/Product/flatsanglechannelbg.png';
import '../Pages.css';
import ReactGA from 'react-ga';
import { useEffect } from 'react';
const FlatsAngleChannelAndBeamManfacturer = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    }, [])
    return (<div>
        <PageHeader title='Flats, Angle, Channel & Beam Manfacturer in India' background={flatsanglechannelbg}/>
        <div className='product-info'>
            <Row gutter={[32, 16]}>
                <Col xs={24} md={12}>
                    <img src={flatsanglechannelmanufacturer} width="100%" height="100%" className='product-img' alt='' />
                </Col>
                <Col xs={24} md={12}>
                    <div className='product-info-title'>Flats, Angle, Channel & Beam Manufacturer, Supplier, and Stockists in India</div>
                    <div className='product-info-description'>Flo Pipes & Fittings is a well-known <b>Flats, Angle, Channel & Beam Manufacturer, Supplier, and Exporter in India</b>. We have a significant manufacturer and supplier of Stainless Steel <u>Angle, Channel, Flat Bar & Beam.</u> Flats, Angle, Channel & Beam are utilised in many sectors, including oil and gas, petroleum, petrochemical, chemical, machine construction, cars, and so on. To suit the demands of our clients, these Flats, Angle, Channel & Beam are available in a range of sizes, specifications, grade materials, and forms.</div>
                </Col>
                <Col xs={0} md={24}><br /></Col>
            </Row>
            <div className='product-info-title'>Flats, Angle, Channel & Beam Manufacturer, Carbon Steel Flats, Angle, Channel & Beam Supplier, Duplex Steel Flats, Angle, Channel & Beam Stockists, Nickel Alloy Flats, Angle, Channel & Beam Manufacturer in India.</div>
            <div className='product-info-description'>Flo Pipes & Fittings is one of India's leading manufacturer of high-quality <u>Flats, Angle, Channel & Beam</u> that are used in a variety of industries. <b>Flats, Angle, Channel & Beam</b> is created by pressing a hard circular steel 'material' over a form and heating it until the steel is wrought into a Flats, Angle, Channel & Beam. Our <em>Adaptors</em> provide consistent strength and structure throughout the Flats, Angle, Channel & Beam body. As a result, these Flats, Angle, Channel & Beam can withstand greater temperatures, higher pressures, corrosive environments, and increased mechanical stress.</div>
            <div className='product-info-description'> We have a competent team to ensure the quality of <b>Flats, Angle, Channel & Beam</b> and other goods throughout the manufacturing and supplying process, which includes selecting high-quality raw materials, turning them into completed products, identifying, packing, storage, and transportation. We are also the largest Supplier of <a href="/products/socketweld-fittings">Socketweld Fittings</a>.</div>
            <br />
            <div className='product-info-title'>Flats, Angle, Channel & Beam Specifications</div>
            <div className="table-responsive">
                <table border="1" className='product-info-description'>
                    <tbody>
                        <tr>
                            <th colSpan="2"><strong>Flats, Angle, Channel & Beam</strong></th>
                        </tr>
                        <tr>
                            <td><strong>Stainless Steel</strong></td>
                            <td> SA 240 TP 304/ 304L/ 304H/ 316/ 316L/ 316Ti/ 317/ 317L/ 321/ 309/ 310/ 347/ 409/ 410/
                                430/ 904L Etc.</td>
                        </tr>
                        <tr>
                            <td><strong>Other Alloys </strong></td>
                            <td> : Nickel, Monel, Inconel, Hastelloy, Alloy20, Incoloy, Duplex & Super Duplex, 5M06-254,
                                Carbon Steel, Alloy Steel, Copper, Brass, Cupro Nickel, Aluminium, Titanium Etc. </td>
                        </tr>

                        <tr>
                            <td><strong>Types </strong></td>
                            <td> Angle, Channel, Flat Bar, Beams Etc</td>
                        </tr>


                    </tbody>
                </table>
            </div>
            <br />
            <div className='product-info-title'>Types of Flats, Angle, Channel & Beam</div>
            <Row align='middle' gutter={[32, 16]}>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={flatbarmanufacturer} width="100%" height="100%" alt='' />
                    <div className='pipes-type-name product-info-title'>Flat</div>
                </Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={anglemanufcaturer} width="100%" height="100%" alt='' />
                    <div className='pipes-type-name product-info-title'>Angle</div>
                </Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={channelmanufacturer} width="100%" height="100%" alt='' />
                    <div className='pipes-type-name product-info-title'>Channel</div>
                </Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={beammanufacturer} width="100%" height="100%" alt='' />
                    <div className='pipes-type-name product-info-title'>Beam</div>
                </Col>
                <Col xs={3} sm={0}></Col>
            </Row>
            {/* <br />
            <div className='product-info-title'>Sheet, Plate and Coils Supplier - Additional Information</div>
            <div className='product-info-description'>
                <ol>
                    <li>Sheet, Plate and Coils Payment Modes: LC (Letter of Credit), TT (Telegraphic Transfer or Wire Transfer), Cheque, and others.</li>
                    <li>Sheet, Plate and Coils Packaging: Sheet, Plate and Coils are packaged with caution and safety so they reach our client’s as good as he would expect. Packaging Charges Extra.</li>
                    <li>Sheet, Plate and Coils Port of Dispatch: Mumbai ports in the Maharashtra state of India.</li>
                    <li>Sheet, Plate and Coils Tax: 18% GST.</li>
                </ol>
            </div>
            <div style={{ padding: '2vw 0', textAlign: 'center' }}><span className='markup-text'>Flo Pipes & Fittings is a leading Carbon Steel Pipes and Tubes Manufacturer in India.</span></div>
            <br />
            <div className='product-info-title'>Flats, Angle, Channel & Beam Supplier - Additional Information</div>
            <div className='product-info-description'>
                <ol>
                    <li>Flats, Angle, Channel & Beam Payment Modes: LC (Letter of Credit), TT (Telegraphic Transfer or Wire Transfer), Cheque, and others.</li>
                    <li>Flats, Angle, Channel & Beam Packaging: Flats, Angle, Channel & Beam are packaged with caution and safety so they reach our client’s as good as he would expect. Packaging Charges Extra.</li>
                    <li>Flats, Angle, Channel & Beam Port of Dispatch: Mumbai ports in the Maharashtra state of India.</li>
                    <li>Flats, Angle, Channel & Beam Tax: 18% GST.</li>
                </ol>
            </div>
            <div className='product-info-title'>Applications & Uses of Flats, Angle, Channel & Beam</div>
            <div className='product-info-description'>
                <ul>
                    <li>Flats, Angle used in Sugar Industry.</li>
                    <li>Channel & Beam used in Power Industry.</li>
                    <li>Flats & Beam used in ShipBuilding Industry.</li>
                    <li>Angle & Channel used in Mechanical and Plant engineering.</li>
                    <li>Flats & Channel used in Food, Beverage, and Dairy.</li>
                    <li>Flats, Angle, Channel & Beam used in Oil and Gas Plant.</li>
                    <li>Angle & Beam used in Process Instrumentation.</li>
                </ul>
            </div> */}
        </div>
    </div>)
}
export default FlatsAngleChannelAndBeamManfacturer;