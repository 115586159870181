import { Carousel, Col, Row } from 'antd';
import "./Home.css";
import ansi from '../../img/standardFollows/ansi.png';
import asme from '../../img/standardFollows/asme.png';
import astm from '../../img/standardFollows/astm.png';
import bis from '../../img/standardFollows/bis.png';
import bsi from '../../img/standardFollows/bsi.png';
import ce from '../../img/standardFollows/ce.png';
import cen from '../../img/standardFollows/cen.png';
import din from '../../img/standardFollows/din.png';
import jis from '../../img/standardFollows/jis.png';

const ListOfFollows_MD = [ansi, asme, astm, bis, bsi, ce, cen, din, jis]

const ListOfFollows_XS = [
    [ansi, asme, astm],
    [bis, bsi, ce],
    [cen, din, jis],
]

const StandardFollowesSlider = () => {
    return (<div className='brand-details'>
        <div className='brand_slider_title'>STANDARD FOLLOWS</div>
        <br />
        <br />
        <div>
            <Row>
                <Col xs={0} md={24}>
                    <Row gutter={[32, 0]} className='m3' justify='space-between' align='middle'>
                        {ListOfFollows_MD.map(img => <Col span={2}>
                            <div>
                                <img src={img} width="100%" alt='' />
                            </div>
                        </Col>)}
                    </Row>
                </Col>
                <Col xs={24} md={0}> <Carousel autoplay>{ListOfFollows_XS.map(item => <div>
                    <Row gutter={[32, 0]} className='m3'>
                        {item.map(img => <Col span={8}>
                            <div>
                                <img src={img} width="100%" alt='' />
                            </div>
                        </Col>)}
                    </Row>
                </div>)}
                </Carousel></Col>
            </Row>
        </div>
    </div>)
}
export default StandardFollowesSlider;