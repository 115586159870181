import './Products.css';
import PageHeader from '../../PageHeader';
import { Row, Col } from 'antd';
import Flanges from '../../img/Product/Flanges.jpg';
import sliponflange from '../../img/Product/sliponflange.jpg';
import lapjointflange from '../../img/Product/lapjointflange.jpg';
import weldneckflange from '../../img/Product/weldneckflange.jpg';
import blindflange from '../../img/Product/blindflange.jpg';
import ringjointfaceFlange from '../../img/Product/ringjointfaceFlange.jpg';
import socketweldflange from '../../img/Product/socketweldflange.jpg';
import threadedflange from '../../img/Product/threadedflange.jpg';
import longweldingneck from '../../img/Product/longweldingneck.jpg';
import orificeflange from '../../img/Product/orificeflange.jpg';
import FlangesBg from '../../img/Product/FlangesBg.jpg';
import '../Pages.css';
import ReactGA from 'react-ga';
import { useEffect } from 'react';
const FlangesManfacturer = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    }, [])
    return (<div>
        <PageHeader title='Flanges Manfacturer in India' background={FlangesBg}/>
        <div className='product-info'>
            <Row gutter={[32, 16]}>
                <Col xs={24} md={12}>
                    <img src={Flanges} width="100%" height="100%" className='product-img' alt='' />
                </Col>
                <Col xs={24} md={12}>
                    <div className='product-info-title'>Flanges Manufacturer, Supplier, and Stockists in India</div>
                    <div className='product-info-description'>Flo Pipes & Fittings is a well-known <b>Flanges Manufacturer, Supplier, and Exporter in India</b>. We have a significant manufacturer and supplier of Stainless Steel <u>Slip-on Flange</u>, as well as Lap Joint Flange. Flanges are utilised in many sectors, including oil and gas, petroleum, petrochemical, chemical, machine construction, cars, and so on. To suit the demands of our clients, these Flanges are available in a range of sizes, specifications, grade materials, and forms.</div>
                </Col>
                <Col xs={0} md={24}><br /></Col>
            </Row>
            <div className='product-info-title'>Flanges Manufacturer, Carbon Steel Flanges Supplier, Duplex Steel Flanges Stockists, Nickel Alloy Flanges Manufacturer in India.</div>
            <div className='product-info-description'>Flo Pipes & Fittings is one of India's leading manufacturer of high-quality <u>Flanges</u> that are used in a variety of industries. <b>Weld Neck Flange</b> is created by pressing a hard circular steel 'material' over a form and heating it until the steel is wrought into a hollow Flange. Our <em>Blind Flange</em> provide consistent strength and structure throughout the Flanges body. As a result, these Flanges can withstand greater temperatures, higher pressures, corrosive environments, and increased mechanical stress.</div>
            <div className='product-info-description'>We have a competent team to ensure the quality of <b>Flanges</b> and other goods throughout the manufacturing and supplying process, which includes selecting high-quality raw materials, turning them into completed products, identifying, packing, storage, and transportation. We are also the largest Supplier of <a href="/products/sheet-plate-and-coils">Sheet, Plate and Coils.</a></div>
            <br />
            <div className='product-info-title'>Flanges Specifications</div>
            <div className="table-responsive">
                <table border="1" className='product-info-description'>
                    <tbody>
                        <tr>
                            <th colSpan="2"><strong>Flanges</strong></th>
                        </tr>
                        <tr>
                            <td><strong>Size</strong></td>
                            <td>1/4"NB to 72"NB</td>
                        </tr>
                        <tr>
                            <td><strong>Type</strong></td>
                            <td>Slip-on Flange, Lap Joint Flange, Weld Neck Flange, Blind Flange, Ring Joint Face (RTJ) Flange, Socketweld Flange, Threaded Flange, Long Welding Neck, Orifice Flange.</td>
                        </tr>
                        <tr>
                            <td colSpan="2"><strong>Grade</strong></td>
                        </tr>
                        <tr>
                            <td><strong>Stainless Steel Flanges </strong></td>
                            <td>ASTM A403, WP316/316L, ASTM A403 SA / A 774 WP-S, WP-W, WP-WX 304/304L, ASTM A182 F316L, 304L, DIN 1.4301, DIN1.4306, DIN 1.4401, DIN 1.4404</td>
                        </tr>
                        <tr>
                            <td><strong>Duplex & Super Duplex Steel Flanges </strong></td>
                            <td>ASTM A 815, ASME SA 815 UNS NO S31803, S32205. UNS S32750, S32950. Werkstoff No. 1.4462</td>
                        </tr>
                        <tr>
                            <td><strong>Carbon Steel Flanges </strong></td>
                            <td>ASTM A234, ASME SA234 WPB , WPBW, WPHY 42, WPHY 46, WPHY 52, WPH 60, WPHY 65 & WPHY 70.</td>
                        </tr>
                        <tr>
                            <td><strong>Alloy Steel Flanges </strong></td>
                            <td>ASTM / ASME A/SA 234 Gr. WP 1, WP 5, WP 9, WP 11, WP 12, WP 22, WP 91</td>
                        </tr>
                        <tr>
                            <td><strong>Nickel Alloy Flanges </strong></td>
                            <td>ASTM / ASME SB 564 UNS 2200 ( NICKEL 200 ), UNS 4400 (MONEL 400 ), UNS 8825 INCONEL (825), UNS 6600 (INCONEL 600 ), UNS 6601 ( INCONEL 601 ), UNS 6625 (INCONEL 625), UNS 10276 ( HASTELLOY C 276 )<br />ASTM / ASME SB 160 UNS 2201 (NICKEL 201 ),<br />ASTM / ASME SB 472 UNS 8020 ( ALLOY 20 / 20 CB 3 ).</td>
                        </tr>

                    </tbody>
                </table>
            </div>
            <br />
            <div className='product-info-title'>Types of Flanges</div>
            <Row align='middle' gutter={[32, 16]}>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={sliponflange} width="100%" height="100%" alt='' />
                    <div className='pipes-type-name product-info-title'>Slip-on Flange</div>
                </Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={lapjointflange} width="100%" height="100%" alt='' />
                    <div className='pipes-type-name product-info-title'>Lap Joint Flange</div>
                </Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={weldneckflange} width="100%" height="100%" alt='' />
                    <div className='pipes-type-name product-info-title'>Weld Neck Flange</div>
                </Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={blindflange} width="100%" height="100%" alt='' />
                    <div className='pipes-type-name product-info-title'>Blind Flange</div>
                </Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={ringjointfaceFlange} width="100%" height="100%" alt='' />
                    <div className='pipes-type-name product-info-title'>Ring Joint Face (RTJ) Flange</div>
                </Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={socketweldflange} width="100%" height="100%" alt='' />
                    <div className='pipes-type-name product-info-title'>Socketweld Flange</div>
                </Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={threadedflange} width="100%" height="100%" alt='' />
                    <div className='pipes-type-name product-info-title'>Threaded Flange</div>
                </Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={longweldingneck} width="100%" height="100%" alt='' />
                    <div className='pipes-type-name product-info-title'>Long Welding Neck</div>
                </Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={orificeflange} width="100%" height="100%" alt='' />
                    <div className='pipes-type-name product-info-title'>Orifice Flange</div>
                </Col>
                <Col xs={3} sm={0}></Col>
            </Row>
            {/* <br />
            <div className='product-info-title'>Flanges Supplier - Additional Information</div>
            <div className='product-info-description'>
                <ol>
                    <li>Flanges Payment Modes: LC (Letter of Credit), TT (Telegraphic Transfer or Wire Transfer), Cheque, and others.</li>
                    <li>Flanges Packaging: Flanges are packaged with caution and safety so they reach our client’s as good as he would expect. Packaging Charges Extra.</li>
                    <li>Flanges Port of Dispatch: Mumbai ports in the Maharashtra state of India.</li>
                    <li>Flanges Tax: 18% GST.</li>
                </ol>
            </div>
            <div style={{ padding: '2vw 0', textAlign: 'center' }}><span className='markup-text'>Flo Pipes & Fittings is a leading Carbon Steel Pipes and Tubes Manufacturer in India.</span></div>
            <br />
            <div className='product-info-title'>Applications & Uses of Flanges</div>
            <div className='product-info-description'>
                <ul >
                    <li>Flanges used in Sugar Industry.</li>
                    <li>Flanges used in Power Industry.</li>
                    <li>Flanges used in ShipBuilding Industry.</li>
                    <li>Flanges used in Mechanical and Plant engineering.</li>
                    <li>Flanges used in Food, Beverage, and Dairy.</li>
                    <li>Flanges used in Oil and Gas Plant.</li>
                    <li>Flanges used in Process Instrumentation.</li>
                </ul>
            </div> */}
        </div>
    </div>)
}
export default FlangesManfacturer;