import './Products.css';
import PageHeader from '../../PageHeader';
import { Row, Col } from 'antd';
import sheetplatemanufacturer from '../../img/Product/sheetplatemanufacturer.jpeg';
import stainlesssteelsheet from '../../img/Product/stainlesssteelsheet.jpg';
import stainlesssteelplate from '../../img/Product/stainlesssteelplate.jpg';
import coilmanufacturerindia from '../../img/Product/coilmanufacturerindia.jpg';
import sheetplateBg from    '../../img/Product/sheetplateBg.jpg';
import '../Pages.css';
import ReactGA from 'react-ga';
import { useEffect } from 'react';
const SheetPlateAndCoilsManfacturer = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    }, [])
    return (<div>
        <PageHeader title='Sheet, Plate and Coils Manfacturer in India' background={sheetplateBg}/>
        <div className='product-info'>
            <Row gutter={[32, 16]}>
                <Col xs={24} md={12}>
                    <img src={sheetplatemanufacturer} width="100%" height="100%" className='product-img' alt='' />
                </Col>
                <Col xs={24} md={12}>
                    <div className='product-info-title'>Sheet, Plate and Coils Manufacturer, Supplier, and Stockists in India</div>
                    <div className='product-info-description'>Flo Pipes & Fittings is a well-known <b>Sheet, Plate and Coils Manufacturer, Supplier, and Exporter in India</b>. We have a significant manufacturer and supplier of Stainless Steel <u>Sheet, Plate and Coils</u>. Stainless Steel Sheet, Plate and Coils are utilised in many sectors, including oil and gas, petroleum, petrochemical, chemical, machine construction, cars, and so on. To suit the demands of our clients, these Sheet, Plate and Coils are available in a range of sizes, specifications, grade materials, and forms.</div>
                </Col>
                <Col xs={0} md={24}><br /></Col>
            </Row>
            <div className='product-info-title'>Stainless Steel Sheet, Plate and Coils Manufacturer, Carbon Steel Sheet, Plate and Coils Supplier, Duplex Steel Sheet, Plate and Coils Stockists, Nickel Alloy Sheet, Plate and Coils Manufacturer in India.</div>
            <div className='product-info-description'>Flo Pipes & Fittings is one of India's leading manufacturer of high-quality <u>Buttweld Fittings</u> that are used in a variety of industries. <b>Elbow Fittings</b> is created by pressing a hard circular steel 'material' over a form and heating it until the steel is wrought into a hollow Fittings. Our <em>Cross Tee</em> provide consistent strength and structure throughout the Buttweld Fittings body. As a result, these Buttweld Fittings can withstand greater temperatures, higher pressures, corrosive environments, and increased mechanical stress.</div>
            <div className='product-info-description'> We have a competent team to ensure the quality of <b>Sheet, Plate and Coils</b> and other goods throughout the manufacturing and supplying process, which includes selecting high-quality raw materials, turning them into completed products, identifying, packing, storage, and transportation. We are also the largest Supplier of <a href="/products/stainless-steel-pipes-and-tubes">Pipes & Tubes</a>.</div>
            <br />
            <div className='product-info-title'>Sheet, Plate and Coils Specifications</div>
            <div className="table-responsive">
                <table border="1" className='product-info-description'>
                    <tbody>
                        <tr>
                            <th colSpan="2"><strong>Sheet, Plate and Coils</strong></th>
                        </tr>
                        <tr>
                            <td><strong>Size</strong></td>
                            <td>0.5mm to 200mm thick in 1000 mm to 3000 mm width & 2500 mm to 12500 mm length available.</td>
                        </tr>
                        <tr>
                            <td colSpan="2"><strong>Grade</strong></td>
                        </tr>
                        <tr>
                            <td><strong>Stainless steel</strong></td>
                            <td>ASTM / ASME SA 182 F 304 , 304L , 304H, 309H, 310H , 316 , 316H , 316L , 316 LN , 317 , 317L , 321 , 321H , 347 , 347 H<br />
                                ASTM / ASME A105 / ASTM / ASME A 350 LF 2 .</td>
                        </tr>
                        <tr>
                            <td><strong>Duplex steel</strong></td>
                            <td>ASTM / ASME SA 182 F 44 , F 45 , F51 , F 53 , F 55 , F 60 , F 61.</td>
                        </tr>
                        <tr>
                            <td><strong>Alloy steel</strong></td>
                            <td>ASTM / ASME A 182 GR F 5, F 9 , F 11 , F 12 , F 22 , F 91.</td>
                        </tr>
                        <tr>
                            <td><strong>Nickel & Alloy Steel</strong></td>
                            <td>ASTM / ASME SB 564 UNS 2200 ( NICKEL 200 ), UNS 4400 (MONEL 400 ), UNS 8825 INCONEL (825), UNS 6600 (INCONEL 600 ), UNS 6601 ( INCONEL 601 ), UNS 6625 (INCONEL 625), UNS 10276 ( HASTELLOY C 276 )<br />ASTM / ASME SB 160 UNS 2201 (NICKEL 201 ),<br />ASTM / ASME SB 472 UNS 8020 ( ALLOY 20 / 20 CB 3 ).</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br />
            <div className='product-info-title'>Types of Sheet, Plate and Coils</div>
            <Row align='middle' gutter={[32, 16]}>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={stainlesssteelsheet} width="100%" height="100%" alt='' />
                    <div className='pipes-type-name product-info-title'>Sheet</div>
                </Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={stainlesssteelplate} width="100%" height="100%" alt='' />
                    <div className='pipes-type-name product-info-title'>Plate</div>
                </Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={coilmanufacturerindia} width="100%" height="100%" alt='' />
                    <div className='pipes-type-name product-info-title'>Coils</div>
                </Col>
                <Col xs={3} sm={0}></Col>
            </Row>
            {/* <br />
            <div className='product-info-title'>Sheet, Plate and Coils Supplier - Additional Information</div>
            <div className='product-info-description'>
                <ol>
                    <li>Sheet, Plate and Coils Payment Modes: LC (Letter of Credit), TT (Telegraphic Transfer or Wire Transfer), Cheque, and others.</li>
                    <li>Sheet, Plate and Coils Packaging: Sheet, Plate and Coils are packaged with caution and safety so they reach our client’s as good as he would expect. Packaging Charges Extra.</li>
                    <li>Sheet, Plate and Coils Port of Dispatch: Mumbai ports in the Maharashtra state of India.</li>
                    <li>Sheet, Plate and Coils Tax: 18% GST.</li>
                </ol>
            </div>
            <div style={{ padding: '2vw 0', textAlign: 'center' }}><span className='markup-text'>Flo Pipes & Fittings is a leading Carbon Steel Pipes and Tubes Manufacturer in India.</span></div>
            <br />
            <div className='product-info-title'>Applications & Uses of Sheet, Plate and Coils</div>
            <div className='product-info-description'>
                <ul>
                    <li>Sheet, Plate and Coils used in Sugar Industry.</li>
                    <li>Sheet, Plate and Coils used in Power Industry.</li>
                    <li>Sheet, Plate and Coils used in ShipBuilding Industry.</li>
                    <li>Sheet, Plate and Coils used in Mechanical and Plant engineering.</li>
                    <li>Sheet, Plate and Coils used in Food, Beverage, and Dairy.</li>
                    <li>Sheet, Plate and Coils used in Oil and Gas Plant.</li>
                    <li>Sheet, Plate and Coils used in Process Instrumentation.</li>
                </ul>
            </div> */}
        </div>
    </div>)
}
export default SheetPlateAndCoilsManfacturer;